import React, { useState } from "react";
import "../Footer/footer.css";
import ScheduleButton from "../ScheduleButton/ScheduleButton";

export default function Footer() {
  return (
    <>
      <div className="footer_wrapper">
        <div className="footer p-4 blue-bg">
          <div className="row justify-content-center">
            <div className="col-lg-4 col-md-6 mb-4 me-2">
              <h3 className="font-semi">Quantafic Business Solution</h3>
              <h6 className="col-10 ps-5 mt-4 font-reg grey">
                Office 319, Building No 2, Sector 1, Millennium Business Park
                (MBP) Mahape, Navi Mumbai:-400710, Maharashtra
              </h6>
            </div>
            <div className="col-lg-2  col-md-6 mb-4 me-2">
              <h5 className="font-reg">Useful Links</h5>
              <ul className="p-0">
                <li className="mb-3 mt-3 ms-0">
                  <a href="#">
                    {/* {' '} */}
                    <h6 className="font-reg grey">Terms of service</h6>
                    {/* {' '} */}
                  </a>
                </li>
                <li className="mb-3 mt-3 ms-0">
                  <a href="/privacy" >
                    {" "} 
                    <h6 className="font-reg grey">Privacy policy</h6>{" "}
                  </a>
                </li>
              </ul>
            </div>
            <div className="col-lg-3 col-md-6 mb-4 me-2">
              <h5 className="font-reg">Contact Us</h5>
              <div className="contact-info">
                <ul className="list-unstyled p-0">
                  <li className="mb-3 mt-3 ms-0">
                    {/* <a href="#"> */}
                    {/* <i className="fa fa-phone me-3"></i>{' '} */}
                    <h6 className="font-reg grey"> (+91) 22 4015 8439</h6>
                    {/* </a> */}
                  </li>
                  <li className="mb-3 mt-3 ms-0">
                    {/* <a href="#"> */}
                    {/* <i className="fa fa-envelope me-3"></i>{' '} */}
                    <h6 className="font-reg grey">
                      contact@quantafic.com
                    </h6>{" "}
                    {/* </a> */}
                  </li>
                </ul>
              </div>
            </div>

            {/* <div className="col-lg-3 col-md-6 mb-4">
              <h5 className="font-reg">Stay Connected</h5>
              <ul className="social-network d-flex align-items-center p-0 ">
                <li>
                  <a href="#">
                    <i className="fab fa-facebook-f"></i>
                  </a>
                </li>
                <li>
                  <a href="#">
                    <i className="fab fa-twitter"></i>
                  </a>
                </li>
                <li>
                  <a href="#">
                    <i className="fab fa-google-plus-g"></i>
                  </a>
                </li>
              </ul>
            </div> */}
          </div>
        </div>
        <div className="copyright-section">
          <h6 className="p-0 dark-blue">
            {/* Copyright © 2018. All rights reserved. Quantafic Business Solutions LLP */}
            Copyright{" "}
            <a href="#">© {new Date().getFullYear() + ""} Quantafic Inc</a> All
            Rights Reserved
          </h6>
        </div>
      </div>
    </>
  );
}
