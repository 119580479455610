import React from 'react'
import Breadcrupms from '../../Breadcrupms/Breadcrupms'
import Footer from '../../Footer/Footer'
import Navbar2 from '../../Navbar/Navbar2'
import ScanAI from '../../../Assests/Images/qscan_ai/Q-ScanAI.png'
import Accuracy from '../../../Assests/Images/qscan_ai/Accuracy.png'
import Speed from '../../../Assests/Images/qscan_ai/Speed.png'
import GoogleTranslate from '../../../Assests/Images/qscan_ai/Google Translate.png'
import HandWithPen from '../../../Assests/Images/qscan_ai/Hand With Pen.png'
import CloudBackupRestore from '../../../Assests/Images/qscan_ai/Cloud Backup Restore.png'
import TickBox from '../../../Assests/Images/qscan_ai/Tick Box.png'
import '../OCR/ocr.css'

function OCR() {
    return (
        <>
            <Navbar2/>
            <Breadcrupms title='Q-Scan.ai' />
            <div className="container">
                <div class="card mt-4 border-0 p-5">
                    <div class="row g-0 justify-content-center">
                        <h5 class="font-med card-title ">Machine learning led OCR to Capture details from any form of Image and avoid
                            manual data entry.</h5>
                    </div>
                    <img src={ScanAI} class="img-fluid rounded-start mt-3 " alt="..."/>
                </div>
            </div>
            <div class="container">
                <div class="card mt-4 mb-4 p-5 border-0">
                    <div class="ocr d-flex flex-column mt-4">
                        <div class="row g-0 justify-content-center">
                            <div class="col-md-1 me-4">
                                <img src={Accuracy}  alt="..."/>
                            </div>
                            <div class="col-md-8  ">
                                <div class="card-body">
                                    <h5 class="font-reg card-title text-decoration-underline">Accuracy 96%</h5>
                                    <h6 class="font-reg card-title">Extract data from any form of document. Tested on large volume of different document image qualities, etc. 
                                    </h6>
                                </div>
                            </div>
                        </div>
                        <div class=" A2 row g-0 justify-content-center">
                            <div class="col-md-1 me-4">
                                <img src={Speed} class="img-fluid rounded-start" alt="..."/>
                            </div>
                            <div class="col-md-8  ">
                                <div class="card-body">
                                    <h5 class="font-reg card-title text-decoration-underline">Speed 0.51 sec
                                        Response time</h5>
                                    <h6 class="font-reg card-title">The cloud hosted solution with median response time. 
                                    </h6>
                                </div>
                            </div>
                        </div>
                        <div class=" A3 row g-0 justify-content-center">
                            <div class="col-md-1 me-4">
                                <img src={GoogleTranslate} class="img-fluid rounded-start" alt="..."/>
                            </div>
                            <div class="col-md-8  ">
                                <div class="card-body">
                                    <h5 class="font-reg card-title text-decoration-underline">Multi-lingual 30 Languages
                                    </h5>
                                    <h6 class="font-reg card-title">The algorithm has been extended to recognize upto 30 languages worldwide. 
                                    </h6>
                                </div>
                            </div>
                        </div>
                        <div class=" A4 row g-0 justify-content-center">
                            <div class="col-md-1 me-4">
                                <img src={HandWithPen} class="img-fluid rounded-start" alt="..."/>
                            </div>
                            <div class="col-md-8  ">
                                <div class="card-body">
                                    <h5 class="font-reg card-title text-decoration-underline">Handwritten 85% + 
                                    </h5>
                                    <h6 class="font-reg card-title">The Q-ScanAi solution supports printed as well as hand-written documents efficiently. 
                                    </h6>
                                </div>
                            </div>
                        </div>
                        <div class=" A5 row g-0 justify-content-center">
                            <div class="col-md-1 me-4">
                                <img src={CloudBackupRestore} class="img-fluid rounded-start" alt="..."/>
                            </div>
                            <div class="col-md-8  ">
                                <div class="card-body">
                                    <h5 class="font-reg card-title text-decoration-underline">Cloud Hosted 100% 
                                    </h5>
                                    <h6 class="font-reg card-title">SaaS Model as hosted on cloud and extended through ReST API, so you do not need to maintain heavy infrastructure. 
                                    </h6>
                                </div>
                            </div>
                        </div>
                        <div class=" A6 row g-0 justify-content-center">
                            <div class="col-md-1 me-4">
                                <img src={TickBox} class="img-fluid rounded-start" alt="..."/>
                            </div>
                            <div class="col-md-8  ">
                                <div class="card-body">
                                    <h5 class="font-reg card-title text-decoration-underline">High throughput 3,000 requests per minute.
                                    </h5>
                                    <h6 class="font-reg card-title">We offer your quota of 3,000 requests every minute, which is further extensible.
                                    </h6>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </>
    )
}

export default OCR
