import React from 'react'
import Breadcrupms from '../../Breadcrupms/Breadcrupms'
import Footer from '../../Footer/Footer'
import Navbar2 from '../../Navbar/Navbar2'
import verify1 from '../../../Assests/Images/mobile_app/verify1.png'
import verify2 from '../../../Assests/Images/mobile_app/verify2.png'
import kisan1 from '../../../Assests/Images/mobile_app/kisan1.png'
import kisan2 from '../../../Assests/Images/mobile_app/kissan2.png'
import nab_login from '../../../Assests/Images/mobile_app/nab_login.png'
import nab_warehouse from '../../../Assests/Images/mobile_app/nab_warehouse.png'
import click_login from '../../../Assests/Images/mobile_app/1click_login.png'
import click_dash from '../../../Assests/Images/mobile_app/1click_dash.png'
import C_dash from '../../../Assests/Images/mobile_app/C_dash.webp'
import C_deatils from '../../../Assests/Images/mobile_app/C_deatils.webp'
import '../MobApp/mob.css'




function MobApp() {
  return (
    <>
      <Navbar2/>
      <Breadcrupms title='Mobile Applications'/>
      <div className="container p-5">
      <div class="card align-items-center border-0">
            <h5 class="font-reg">
              Quantafic expertise in Mobile application development. We build
              mobile application in Native as well as Hybrid technology as per
              the user requirements. Quantafic mobile application development
              coupled with expertise in backend creation help in providing end
              to end support to our clients.
            </h5>
            <div class="cardR border mt-5">
              <div class="container">
                <div class="row ">
                  <h5 class="Metro-SemiBold">Few of the Mobile application screens.</h5>
                  <div class="mockup col ">
                    <h5 class="mt-3 Metro-Regular">Q-Verifi</h5>
                    <hr/>
                    <div class="col d-flex justify-content-center">
                      <img
                        src={verify1}
                        class="img-fluid"
                        alt=""
                      />
                      <img
                        src={verify2}
                        class="img-fluid"
                        alt=""
                      />
                    </div>
                  </div>
                  <div class="mockup col">
                    <h5 class="mt-2 Metro-Regular">Kisan Bhandar</h5>
                    <hr/>
                    <div class="col d-flex justify-content-center">
                      <img
                        src={kisan1}
                        class="img-fluid"
                        alt=""
                      />
                      <img
                        src={kisan2}
                        class="img-fluid"
                        alt=""
                      />
                    </div>
                  </div>
                </div>
                <div class="row mt-3">
                  <div class="mockup col">
                    <h5 class="mt-3 Metro-Regular">Nab Parikshan</h5>
                    <hr/>
                    <div class="col d-flex justify-content-center">
                      <img
                        src={nab_login}
                        class="img-fluid"
                        alt=""
                      />
                      <img
                        src={nab_warehouse}
                        class="img-fluid"
                        alt=""
                      />
                    </div>
                  </div>
                  <div class="mockup col">
                    <h5 class="mt-3 Metro-Regular">Claim Book</h5>
                    <hr/>
                    <div class="col d-flex justify-content-center">
                      <img
                        src={C_dash}
                        class="img-fluid"
                        alt=""
                      />
                      <img
                        src={C_deatils}
                        class="img-fluid"
                        alt=""
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
      </div>
      <Footer/>
    </>
  )
}

export default MobApp
