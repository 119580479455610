import React from 'react'
import Breadcrupms from '../../Breadcrupms/Breadcrupms'
import Footer from '../../Footer/Footer'
import Navbar2 from '../../Navbar/Navbar2'
import '../ResAug/res.css'
import cus_supp from '../../../Assests/Images/resources/cus_supp.png'
import data_analytics from '../../../Assests/Images/resources/data_analytics.png'
import dev_team from '../../../Assests/Images/resources/dev_team.png'
import reports from '../../../Assests/Images/resources/reports.png'
import pro_support from '../../../Assests/Images/resources/pro_support.png'
import JAVA from '../../../Assests/Images/resources/technology/JAVA.png'
import Python from '../../../Assests/Images/resources/technology/Python.png'
import R from '../../../Assests/Images/resources/technology/R.png'
import PowerBI from '../../../Assests/Images/resources/technology/PowerBI.png'
import Dataware from '../../../Assests/Images/resources/technology/Dataware.png'
import SAS from '../../../Assests/Images/resources/technology/SAS.png'
import Tableau from '../../../Assests/Images/resources/technology/Tableau.png'

function ResAug() {
  return (
    <>
      <Navbar2 />
      <Breadcrupms title='Resource Augmentation' />
      <div className="container">
        <div class="cardR align-items-center">
          <h5 class="font-reg mb-5">Quantafic empowers the Business team by providing skilled resources. Quantafic work with leading Multinational Bank, Private Bank and other institutes and help in augmenting resources with appropriate skill and experience.</h5>
          <div class="col-lg-12 mb-5">
            <div class="row justify-content-center m-1">
              <div class=" card col-lg-3 border m-2 p-3">
                <h5 className='font-reg'> - Skilled Resources for Short term & Long term.</h5>
              </div>
              <div class=" card col-lg-3 border m-2 p-3">
                <h5 className='font-reg'> - Flexible Billing model with Monthly/Annual Engagement.</h5>
              </div>
              <div class=" card col-lg-3 border m-2 p-3">
                <h5 className='font-reg'> - MSA with various skills & experience level.</h5>
              </div>
              <div class=" card col-lg-3 border m-2 p-3">
                <h5 className='font-reg'> - Onsite & Offshore model for resource to deliver services.</h5>
              </div>
            </div>
          </div>
          <hr />
          <div class="container mt-5">
            <h5 className='font-reg'>Partial list of activities for which skilled resource are provided</h5>
            <div class="row mb-3">
              <div class="col mt-3 ">
                <div class="res d-flex">
                  <img src={cus_supp} class="img-fluid" alt="" />
                  <h5 className='font-reg  m-4' >Customer support activities</h5>
                </div>
                <div class="res d-flex">
                  <img src={data_analytics} class="img-fluid" alt="" />
                    <h5 className='font-reg m-4' >Data Analytics</h5>
                </div>
                <div class="res d-flex">
                  <img src={dev_team} class="img-fluid" alt=""/ >
                    <h5 className='font-reg m-4' >Augment existing development team</h5>
                </div>
              </div>
              <div class="col mt-3">
                <div class="res d-flex">
                  <img src={reports} class="img-fluid" alt="" />
                  <h5 className='font-reg m-4' >Daily MIS/ Reports/ Dashboard requirements</h5>
                </div>
                <div class="res d-flex">
                  <img src={pro_support} class="img-fluid" alt=""/ >
                    <h5 className='font-reg m-4' >Production Support</h5>
                </div>
              </div>
            </div>
            <div class="col mt-5">
              <h5 className='font-reg' >Quantafic can provide following skill resources with quick turn around time.</h5>
            </div>
            <div class="row justify-content-center">
              <div class="tech col">
              <div class="col d-flex justify-content-center">
                  <img src={JAVA} class="img-fluid rounded-start m-1 " alt=""/> 
                  <img src={Python} class="img-fluid rounded-start  m-1" alt=""/> 
                  <img src={R} class="img-fluid rounded-start  m-1" alt=""/> 
                  <img src={PowerBI} class="img-fluid rounded-start  m-1" alt=""/>                      
              </div>
              <div class="col d-flex justify-content-center">  
                <img src={Dataware} class="img-fluid rounded-start  m-1" alt=""/> 
                <img src={SAS} class="img-fluid rounded-start  m-1" alt=""/> 
                <img src={Tableau} class="img-fluid rounded-start  m-1" alt=""/> 
               </div>
              </div>
            </div>
            <div class="mt-4">
              <h5 className='font-reg' ><i class="fas fa-dot-circle"></i> One of the top 5 Private sector Bank in India Signed Multi year MSA with Quantafic for resources with multiple skills for Data Analytics work.</h5>
              <h5 className='font-reg' ><i class="fas fa-dot-circle"></i> Large Auto Finance company signed MSA for production support resources.</h5>
              <h5 className='font-reg' ><i class="fas fa-dot-circle"></i> Public Sector Bank In India Signed MSA for resources for development of Mobile application and web application.</h5>
              <h5 className='font-reg' ><i class="fas fa-dot-circle"></i> Insurance Company In India Signed MSA for development and application support resources.</h5>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  )
}

export default ResAug
