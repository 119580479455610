import React from 'react'
import Navbar2 from '../../Navbar/Navbar2'
import Breadcrupms from '../../Breadcrupms/Breadcrupms'
import Footer from '../../Footer/Footer'
import Dplatform from '../../../Assests/Images/D-platform.png'
import customer_onboarding from '../../../Assests/Images/customer_onboarding.png'


function DigitalOnboarding() {
  return (
    <>
      <Navbar2/>
      <Breadcrupms title = " Digital Constumer Onboarding"/>
      <div className="conatiner">
      <div class="container p-3">
            <div class="card border-0">
                <div class="row g-0 justify-content-center">
                    <h4 class="card-title text-center font-med " >Q-Digital Platform</h4>
                    <img src={Dplatform} class=" w-25 rounded mx-auto d-block" alt="..."/>
                </div>
                <div class="card-body border rounded-2 mt-4 shadow"  >
                    <h5 class="card-title text-center font-med"><i class="fas fa-handshake"></i>  Meet Customer</h5>
                    <h6 class="card-title text-center font-reg ">Digitalisation helps in quick and effective interaction with customer.</h6>
                </div>
                <i class="fas fa-arrow-down text-center  mt-2"></i>
                <div class="card-body border rounded-2 mt-2 shadow  ">
                    <h5 class="card-title text-center font-med"><i class="fas fa-mobile-alt"></i>  Mobile Application</h5>
                    <h6 class="card-title text-center font-reg">Mobile app helps to capture details and pre-filled application form gets generated.
                    </h6>
                </div>
                <i class="fas fa-arrow-down text-center  mt-2"></i>
                <div class="card-body border rounded-2 mt-2 shadow  ">
                    <h5 class="card-title text-center font-med"><i class="fas fa-file-video"></i>  Video KYC</h5>
                    <h6 class="card-title text-center font-reg">Video KYC meeting the RBI guidelines for KYC compliance.</h6>
                </div>
                <i class="fas fa-arrow-down text-center  mt-2"></i>
                <div class="card-body border rounded-2 mt-2 shadow  ">
                    <h5 class="card-title text-center font-med"><i class="fas fa-id-badge"></i>  Automated ID Check</h5>
                    <h6 class="card-title text-center font-reg">Automated ID check including face match feature.</h6>
                </div>
                <i class="fas fa-arrow-down text-center  mt-2"></i>
                <div class="card-body border rounded-2 mt-2 shadow  ">
                    <h5 class="card-title text-center font-med"><i class="fas fa-id-card"></i>  OCR to Auto Capture Data</h5>
                    <h6 class="card-title text-center font-reg">No dependency on back office for detailed data entry as OCR captures details from document images.</h6>
                </div>
                <i class="fas fa-arrow-down text-center  mt-2"></i>
                <div class="card-body border rounded-2 mt-2 shadow  ">
                    <h5 class="card-title text-center font-med"><i class="fas fa-file-invoice"></i>  Pre-filled Application form</h5>
                    <h6 class="card-title text-center font-reg ">Mobile application triggers bureau check and other database verifications.</h6>
                </div>
                <i class="fas fa-arrow-down text-center  mt-2"></i>
                <div class="card-body border rounded-2 mt-2 shadow  ">
                    <h5 class="card-title text-center font-med"><i class="fas fa-database"></i>  API  with Data Providers</h5>
                    <h6 class="card-title text-center font-reg">Multiple internal and external APIs, Provides data via a single point of query.</h6>
                </div>
                <i class="fas fa-arrow-down text-center  mt-2"></i>
                <div class="card-body border rounded-2 mt-2 shadow  ">
                    <h5 class="card-title text-center font-med"><i class="fas fa-cog"></i>  Instant Auto Allocation of Cases for FI</h5>
                    <h6 class="card-title text-center font-reg">Rule engine auto allocate cases to most optimal verification agency</h6>
                </div>
                <i class="fas fa-arrow-down text-center  mt-2"></i>
                <div class="card-body border rounded-2 mt-2 shadow  ">
                    <h5 class="card-title text-center font-med"><i class="fas fa-cogs"></i>  Rule based Instant Eligibility</h5>
                    <h6 class="card-title text-center font-reg">Rule engine computes customer eligibility across all credit schemes & displays best offers on Mobile.</h6>
                </div>
                <i class="fas fa-arrow-down text-center  mt-2"></i>
                <div class="card-body border rounded-2 mt-2 shadow  ">
                    <h5 class="card-title text-center font-med"><i class="fas fa-chalkboard-teacher"></i>  Customer Onboarded</h5>
                    <h6 class="card-title text-center font-reg">Customer onboarded within few minutes.</h6>
                </div>

                <div class="container">
                    <img class="img-fluid w-100 mt-4 "src={customer_onboarding} alt=""/>
                </div>
                    
            </div>
        </div>
      </div>
      <Footer/>
      
    </>
  )
}

export default DigitalOnboarding
