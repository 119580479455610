import React from 'react'
import Breadcrupms from '../../Breadcrupms/Breadcrupms'
import Footer from '../../Footer/Footer'
import Navbar2 from '../../Navbar/Navbar2'
import amico from '../../../Assests/Images/amico.png'
import pana from '../../../Assests/Images/pana.png'
import benifit from '../../../Assests/Images/benifit.png'


function Verify() {
  return (
    <>
      <Navbar2/>
      <Breadcrupms title = "Q-Verifi"/>
      <div className="container align-content-center">
        <div class="container">
        <div class="card text-dark   mb-3" >
            <div class="card-header">
                <h5 class="font-Med">Features</h5>
            </div> 
              <div class="row g-0 align-items-center">
                <div class=" card-body col-md-4">
                    <h6 class="card-title font-reg"><i class="fas fa-chalkboard-teacher"></i> Single product where multiple
                        processes can be configured </h6>
                    <h6 class="card-title font-reg"><i class="fas fa-users-cog"></i> Rule engine for auto allocation of
                        work among credit managers / agencies / brokers / appraisers.
                    </h6>
                    <h6 class="card-title font-reg"><i class="fas fa-mobile-alt"></i> Mobile App for verifier / appraisers
                    </h6>
                    <h6 class="card-title font-reg"><i class="fas fa-money-check"></i> Centralised MIS</h6>
                    <h6 class="card-title font-reg"><i class="fas fa-undo"></i> Assign/ Pullback/ Reassign features</h6>
                    <h6 class="card-title font-reg"><i class="fas fa-file-invoice"></i> Automatic final report</h6>
                </div>
                <div class="col-md-4 col-7 m-auto">
                    <img src={amico} class="img-fluid rounded-start" alt="..."/>
                </div>
              </div>                
          </div>
          <div class="container mt-5">
                <div class="card text-dark    mb-3" >
                    <div class="card-header">
                        <h5 class="font-med">Business Problems</h5> 
                    </div>
                    <div class="row g-0 align-items-center">
                        <div class=" card-body col-md-4">
                            <h6 class="card-text font-reg "><i class="fas fa-angle-right"></i> Require Digital Appraisal Process
                            </h6>
                            <h6 class="card-text font-reg "><i class="fas fa-angle-right"></i>TAT should be reduced</h6>
                            <h6 class="card-text font-reg "><i class="fas fa-angle-right"></i>Need one product for all activities
                                of credit decisioning process where bank is dependent on external agencies</h6>
                            <h6 class="card-text font-reg "><i class="fas fa-angle-right"></i>Mobile App which capture Images/
                                Video / Lat-Long.</h6>
                            <h6 class="card-text font-reg "><i class="fas fa-angle-right"></i>Auto Allocation of work to different
                                agencies.</h6>
                            <h6 class="card-text font-reg "><i class="fas fa-angle-right"></i>Assign / Reassign / Pullback </h6>
                            <h6 class="card-text font-reg "><i class="fas fa-angle-right"></i>Automated Billing </h6>
                            <h6 class="card-text font-reg "><i class="fas fa-angle-right"></i>Agencies and credit manager
                                performance analysis</h6>
                        </div>
                        <div class="col-md-4 col-7 m-auto ">
                            <img src={pana} class="img-fluid rounded-start" alt="..."/>
                        </div>
                    </div>
                </div>
            </div>  
            <div class="container mt-5">
                <div class="card text-dark  mb-3" >
                    <div class="card-header"> 
                    <h5 class="font-med">Benefits Derived</h5>
                    </div>
                    <div class="row g-0 align-items-center">
                        <div class="card-body col-md-4">
                            <h6 class="card-text font-reg"><i class="fas fa-angle-right"></i> TAT reduced by 50%</h6>
                            <h6 class="card-text font-reg"><i class="fas fa-angle-right"></i>Journey from near 0% Automation to near
                                100% digital process</h6>
                            <h6 class="card-text font-reg"><i class="fas fa-angle-right"></i>More than 7.2 Mn Annual Transactions
                                Processed</h6>
                            <h6 class="card-text font-reg"><i class="fas fa-angle-right"></i>Credit Manager and Agency Performance
                                Analysis helped in improved productivity </h6>
                            <h6 class="card-text font-reg"><i class="fas fa-angle-right"></i>Seamless user experience across all
                                processes</h6>
                        </div>
                        <div class="col-md-4 col-7 m-auto">
                            <img src={benifit} class="img-fluid rounded-start" alt="..."/>
                        </div>
                    </div>
                </div>
            </div>
        </div>
      </div>
      <Footer/>
    </>
  )
}

export default Verify
