import React, { useEffect, useState } from "react";
import Breadcrupms from "../Breadcrupms/Breadcrupms";
import Footer from "../Footer/Footer";
import Navbar2 from "../Navbar/Navbar2";
import "../ReachUs/reach.css";
import { useForm } from "react-hook-form";
import axios from "axios";
import {
  loadCaptchaEnginge,
  LoadCanvasTemplate,
  LoadCanvasTemplateNoReload,
  validateCaptcha,
} from "react-simple-captcha";
import { useGeolocated } from "react-geolocated";

function ReachUs() {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [number, setNumber] = useState("");
  //  const [ company , setComapny ] = useState()
  const [subject, setSubject] = useState("");
  const [message, setMessage] = useState("");
  const [captcha, setCaptcha] = useState(false);

  const { coords } = useGeolocated({
    positionOptions: {
      enableHighAccuracy: false,
    },
    userDecisionTimeout: 5000,
  });

  useEffect(() => {
    loadCaptchaEnginge(6);
  }, []);

  //  handle error
  const handleError = (errors) => {};

  // -----------handleform function-----------------
  const handleForm = (data) => {
    //------------------Captcha Function----------------------
    let userCaptcha = document.getElementById("userCaptcha").value;

    if (validateCaptcha(userCaptcha) === true) {
      // alert('Captcha Matched')
      loadCaptchaEnginge(6);
      document.getElementById("userCaptcha").value = "";
      setCaptcha(true);
    } else {
      alert("Captcha does not match");
      document.getElementById("userCaptcha").value = "";
      setCaptcha(false);
    }

    //--------------------post request---------------------------
    let lat = null;
    let lng = null;

    if (coords === undefined) {
      lat = null;
      lng = null;
    } else {
      lat = coords.latitude;
      lng = coords.longitude;
    }

    axios
      .post("https://leaves.quantafic.in/api/qbs/contact", {
        headers: {
          "Content-Type": "application/json",
          "Cache-Control": "no-cache",
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Methods": "POST, GET",
          "Access-Control-Allow-Headers ":
            " Origin, Content-Type, Accept, Authorization",
          "Referrer-Policy": "origin",
          "Access-Control-Allow-Credentials": "true",
          crossdomain: "true",
          mode: "no-cors",
        },
        body: {
          firstName: firstName,
          lastName: lastName,
          mobNo: number,
          emailId: email,
          subject: subject,
          msgContent: message,
          lat: lat,
          lng: lng,
        },
      })
      .then((response) => {
        if (response.data.status === "Success" && captcha) {
          setFirstName("");
          setLastName("");
          setEmail("");
          setNumber("");
          setSubject("");
          setMessage("");
          alert("Data submitted successfully");
        } else {
          alert("Some error occured");
        }
        // console.log(response.data);
      });
  };

  //--------------------------validations criteria--------------------------

  const formFields = {
    firstName: { required: "Name is Required" },
    LastName: { required: "Name is Required" },
    email: {
      required: "Email is required",
      pattern: {
        value: /^\S+@\S+$/i,
        message: "This is not valid email",
      },
    },
    number: {
      required: "Mobile number is Required",
      pattern: {
        value: /^([+]?[\s0-9]+)?(\d{3}|[(]?[0-9]+[)])?([-]?[\s]?[0-9])+$/i,
        message: "This is not a valid Number",
      },
      maxLength: {
        value: 10,
        message: "Number can't contain more than 10 digits",
      },
      minLength: {
        value: 10,
        message: "Number must contain 10 digits",
      },
    },
    subject: { required: "Subject is required" },
    Message: { required: "Message is required" },
  };

  return (
    <>
      <Navbar2 />
      <Breadcrupms title="Reach Us" />
      <div className="contact">
        <div
          className="container mt-5 mb-5"
          data-aos="fade-up"
          data-aos-delay="100"
        >
          <div className="row gy-4 justify-content-center">
            <div className="col-lg-6 col-md-6">
              <div className="info-item  d-flex flex-column justify-content-center align-items-center lightshade-bg">
                {/* <i class="fas fa-map-marker-alt"></i> */}
                <h3 className="font-med">Our Address</h3>
                <h6 className="font-reg text-center">
                  Office 319, Building No 2, Sector 1, Millennium Business Park
                  (MBP) Mahape, Navi Mumbai :-400710 Maharashtra
                </h6>
              </div>
            </div>
            <div className="col-lg-3 col-md-6">
              <div className="info-item d-flex flex-column justify-content-center align-items-center lightshade-bg">
                {/* <i class="fas fa-envelope"></i> */}
                <h3 className="font-med">Email Us</h3>
                <h6 className="font-reg">contact@quantafic.com</h6>
              </div>
            </div>
            <div className="col-lg-3 col-md-6">
              <div className="info-item  d-flex flex-column justify-content-center align-items-center lightshade-bg">
                {/* <i class="fas fa-phone-alt"></i> */}
                <h3 className="font-med">Call Us</h3>
                <h6 className="font-reg"> (+91) 22 4015 8439</h6>
              </div>
            </div>
          </div>
          <div className="row gy-4 m-auto">
            <div className="col-lg-6 justify-content-center">
              <div className="map m-auto">
                <iframe
                  src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d15079.732773911863!2d73.0173286!3d19.1105865!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x9fa0b94d00e54a99!2sQUANTAFIC%20BUSINESS%20SOLUTIONS!5e0!3m2!1sen!2sin!4v1673355173530!5m2!1sen!2sin"
                  width="550"
                  height="540"
                  style={{ border: 0 }}
                  allowFullScreen=""
                  aria-hidden="false"
                  tabIndex="0"
                  loading="lazy"
                  referrerPolicy="no-referrer-when-downgrade"
                ></iframe>
              </div>
            </div>
            <div className="col-lg-6">
              <form onSubmit={handleSubmit(handleForm, handleError)}>
                <div className="card p-3">
                  {/* <div className="d-md-flex justify-content-evenly"> */}
                  <div className="ReachField_auto flex-column row ">
                    <label className="font-reg p-0">First Name</label>
                    <input
                      className="form-control font-reg"
                      type="text"
                      name="firstName"
                      value={firstName}
                      placeholder="First Name"
                      {...register("firstName", formFields.firstName)}
                      onChange={(e) => setFirstName(e.target.value)}
                    ></input>
                    <small className="text-danger">
                      {errors?.name && errors.name.message}
                    </small>
                  </div>
                  <div className="ReachField_auto flex-column row">
                    <label className="font-reg p-0">Last Name</label>
                    <input
                      className="form-control font-reg"
                      type="text"
                      name="LastName"
                      value={lastName}
                      placeholder="Last Name"
                      {...register("LastName", formFields.LastName)}
                      onChange={(e) => setLastName(e.target.value)}
                    ></input>
                    <small className="text-danger">
                      {errors?.name && errors.name.message}
                    </small>
                  </div>
                  {/* </div> */}
                  {/* <div className="d-md-flex justify-content-evenly"> */}
                  <div className="ReachField_auto flex-column row">
                    <label className="font-reg p-0">Email</label>
                    <input
                      className="form-control font-reg"
                      type="text"
                      name="email"
                      value={email}
                      placeholder="Email Address"
                      {...register("email", formFields.email)}
                      onChange={(e) => setEmail(e.target.value)}
                    ></input>
                    <small className="text-danger">
                      {errors?.email && errors.email.message}
                    </small>
                  </div>
                  <div className="ReachField_auto flex-column row">
                    <label className="font-reg p-0">Mobile Number</label>
                    <input
                      className="form-control font-reg"
                      type="text"
                      name="mob"
                      value={number}
                      placeholder="Mobile Number"
                      {...register("number", formFields.number)}
                      onChange={(e) => setNumber(e.target.value)}
                    ></input>
                    <small className="text-danger">
                      {errors?.number && errors.number.message}
                    </small>
                  </div>
                  {/* </div> */}
                  {/* <div className="d-md-flex justify-content-evenly"> */}
                  <div className="ReachField_sub flex-column row">
                    <label className="font-reg p-0">Subject</label>
                    <input
                      className="form-control font-reg"
                      type="text"
                      name="subject"
                      value={subject}
                      placeholder="subject"
                      {...register("subject", formFields.subject)}
                      onChange={(e) => setSubject(e.target.value)}
                    ></input>
                    <small className="text-danger">
                      {errors?.subject && errors.subject.message}
                    </small>
                  </div>
                  {/* </div> */}
                  {/* <div className="d-md-flex justify-content-evenly"> */}
                  <div className="ReachField_msg flex-column row">
                    <label className="font-reg p-0">Message</label>
                    <input
                      className="form-control font-reg"
                      type="text"
                      name="Message"
                      value={message}
                      placeholder="Message"
                      {...register("Message", formFields.Message)}
                      onChange={(e) => setMessage(e.target.value)}
                    ></input>
                    <small className="text-danger">
                      {errors?.Message && errors.Message.message}
                    </small>
                  </div>
                  <div className="d-md-flex justify-content-center align-items-center m-3">
                    
                    <input
                      className="form-check-input m-3 "
                      type="checkbox"
                      name="terms"
                      
                      style={{ width: "20px", height: "20px" }} // Increase the size of the checkbox
                      {...register("terms", {
                        required: "Please agree to the terms and conditions",
                      })}
                    ></input>
                    <label className="font-reg" style={{fontSize:"14px"}}>
                      I/We authorize Quantafic Business Solutions to store and
                      consume the data for internal use.
                    </label>
                    <small className="text-danger">
                      {errors?.terms && errors.terms.message}
                    </small>
                  </div>
                  <div className="bottomContent p-5">
                    <div className="d-md-flex justify-content-center">
                      <div className="cap me-5">
                        <LoadCanvasTemplate />
                      </div>
                      <div className="ReachField_cap">
                        <label className="font-reg p-0">Enter Captcha</label>
                        <input
                          className="form-control font-reg"
                          type="text"
                          name="userCaptcha"
                          id="userCaptcha"
                          placeholder="Enter Captcha"
                        ></input>
                      </div>
                    </div>
                    <div
                      className="subBtn m-auto "
                      style={{ maxWidth: "fit-content" }}
                    >
                      <button className="btn shadow mt-5" type="submit">
                        <h6>Submit</h6>
                      </button>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}

export default ReachUs;
