import React from 'react'
import '../QverifyHero/hero.css'

const QverifyHeroSection = () => {
  return (
    <>
  
      <div className="backgroundImage">
        <div className="container p-5">
          <div className="col-lg-9 col-12 align-items-center m-auto">
            <div className="firstContent text-center p-5">
              <h1 className='dark-blue font-bold  p-md-5 '>Q-Verifi</h1>
              <h3 className='dark-blue font-med mt-5'>Automates any process which involves interaction between financial institutes and agencies.</h3>
            </div>
           <div className="secondContent p-3  m-auto mt-5 ">
            <div className='col-sm-12'>
             <div className="d-md-flex align-items-center">
              <div className="col-md-4 text-center p-3">
                <h5 className='font-reg'>- Single Product to Automate Multiple loan Processes involving Agencies</h5>
              </div>
              <div>
                <div className="vr"></div>
              </div>

              <div className="col-md-4 text-center p-3">
                <h5 className='font-reg'>- Proven product with multiple implementations including large Pvt.banks and NBFC</h5>
              </div>
              <div>
                <div className="vr hr"></div>
              </div>
              <div className="col-md-4 text-center p-3">
                <h5 className='font-reg'>- API for quick integration with other applications like LOS</h5>
              </div>
             </div>       
            </div>
           </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default QverifyHeroSection
