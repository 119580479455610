import React from 'react'
import { useNavigate } from 'react-router-dom'
import './career.css'
import './CareerData'
// import Career from './Career'

export default function CareerCard(props) {
  
  const navigate = useNavigate()

  const {
    location,
    experience,
    overview,
    position,
    // responsibilites,
    // technical,
    // selection,
    
  } = props
 
  const goToView = () =>{
   
    navigate('/viewcard', {state:{params: props}} )
  }
  // onClick={() => goToView()}

  return (
    <>
      <div className="careercard m-auto shadow-lg">
        <div className="container p-5">
          <div className="d-md-flex justify-content-between align-items-center">
            <h2>{position}</h2>
            <button className="btn shadow"  
              onClick={() => goToView()}> <h6>View</h6> 
              {/* <Link to={props.link} ><h6>View</h6></Link> */}
            </button>
          </div>
          <div className="d-flex grey align-items-center mt-2">
            <h6 className='font-reg m-1'>{location}</h6>
            <div className="line ms-3 me-3"></div>
            <h6 className='font-reg m-1'>{experience}</h6>
          </div>
          <div className="flex mt-5">
            <h5 className='font-med'> Job Overview </h5>
            <h6 className='font-reg mt-3'>{overview} </h6>
          </div>
        </div>
      </div>
    </>
  )
}
