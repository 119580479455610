import React from 'react'
import Breadcrupms from '../Breadcrupms/Breadcrupms'
import Footer from '../Footer/Footer'
import Navbar2 from '../Navbar/Navbar2'
import Slider from "react-slick";
import G6 from '../../Assests/Images/gallery/6.webp'
import G4 from '../../Assests/Images/gallery/4.webp'
import G3 from '../../Assests/Images/gallery/3.webp'
import G10 from '../../Assests/Images/gallery/10.webp'

function Responsive() {

    var settings = {
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: 2,
        slidesToScroll: 1,
        initialSlide: 0,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                    infinite: true,
                    dots: true
                }
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    initialSlide: 2
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            },
            {
                breakpoint: 360,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }
        ]
    }

    return (
        <>
            <Navbar2 />
            <Breadcrupms title='Gallery' />
            <div className="slickcarousel p-3 ">
                <Slider {...settings}>
                    <div>
                        <img src={G6} class="d-block w-100" alt="..." />
                    </div>
                    <div>
                        <img src={G4} class="d-block w-100" alt="..." />
                    </div>
                    <div>
                        <img src={G3} class="d-block w-100" alt="..." />
                    </div>
                    <div>
                        <img src={G10} class="d-block w-100" alt="..." />
                    </div>
                </Slider>
            </div>
            <Footer />
        </>
    )
}

export default Responsive 
