import React from 'react'
import Breadcrupms from '../../Breadcrupms/Breadcrupms'
import Footer from '../../Footer/Footer'
import Navbar2 from '../../Navbar/Navbar2'
import problems from '../../../Assests/Images/case_imgs/problems.png'
import process from '../../../Assests/Images/case_imgs/process.png'
import featurescase from '../../../Assests/Images/case_imgs/features-case.png'

const RCU = () => {
  return (
    <>
      <Navbar2 />
     
      <Breadcrupms title="RCU" />
      <div className="container p-5">
         <div className="problems">
        <h3 className="font-med">Day to Day Challenges</h3>
        <div className="problems d-md-flex justify-content-between align-items-center">
          <div className="statements">
            <h6 className="font-reg p-2">
              {' '}
              - Centralized processing vs. Local agency
            </h6>
            <h6 className="font-reg p-2">
              {' '}
              - Poor accountability of people, Transparency of current state,
              TAT visibility​
            </h6>
            <h6 className="font-reg p-2">
              {' '}
              - Multiple Processes – Screening, Sampling, Seeding, Training, etc
            </h6>
            <h6 className="font-reg p-2"> - Multiple LOS​</h6>
            <h6 className="font-reg p-2">
              {' '}
              - Data security, auditability – Regulator/Bank’s norms​
            </h6>
            <h6 className="font-reg p-2"> - Agency level billing complexity​​</h6>
          </div>
          <div className="col-md-4">
            <img src={problems} class="img-fluid rounded-start" alt="..." />
          </div>
        </div>

         </div>
        <div className='process'>
        <h3 className="font-med">Processes​</h3>

        <div className="d-md-flex  justify-content-between mt-5">
          <div className="col-md-8 content">
            <div class="card-body border rounded-2 mt-4 p-3">
              <h5 class="card-title text-center font-med">
                <i class="fas fa-handshake"></i> Seeding​
              </h5>
              <h6 class="card-title text-center font-reg ">
                Perform seeding activity and record the findings in system.
              </h6>
            </div>
            <div class="card-body border rounded-2 mt-4 p-3">
              <h5 class="card-title text-center font-med">
                <i class="fas fa-handshake"></i> Sampling​
              </h5>
              <h6 class="card-title text-center font-reg ">
                Automate sampling process in RCU depts​.
              </h6>
            </div>
            <div class="card-body border rounded-2 mt-4 p-3">
              <h5 class="card-title text-center font-med">
                <i class="fas fa-handshake"></i>Asset cross-check
              </h5>
              <h6 class="card-title text-center font-reg ">
                Verify the asset state/possession through in-person visit​
              </h6>
            </div>
            <div class="card-body border rounded-2 mt-4 p-3">
              <h5 class="card-title text-center font-med">
                <i class="fas fa-handshake"></i> Billing
              </h5>
              <h6 class="card-title text-center font-reg ">
                Generate comprehensive billing at agency and activity level for
                a date range​
              </h6>
            </div>
            {/* <div class="card-body border rounded-2 mt-4 p-3">
              <h5 class="card-title text-center font-med">
                <i class="fas fa-handshake"></i> Monitoring
              </h5>
              <h6 class="card-title text-center font-reg ">
              Cases flagged as issue/negative flow to Monitoring module for further action​
              </h6>
            </div> */}
          </div>
          <div className="col-md-5">
            <img src={process} class="img-fluid rounded-start" alt="..." />
          </div>
        </div>
        </div>
       
        <div className="features mt-5">

        <h3 className="font-med ">Features</h3>
        <div className=" d-md-flex justify-content-between align-items-center">
          <div className="statements">
            <div className="m-2 p-2">
              <h6 className="font-med">- Mobile App​</h6>
              <h6 className="font-reg">
                For ground-staff.​ Android/iOS.​ Capture images, lat-long​ Data
                security
              </h6>
            </div>
            <div className="m-2 p-2">
              <h6 className="font-med">- Security norms​​</h6>
              <h6 className="font-reg">
                Encrypted PI data.​ AD-authentication​ UAM role for managing
                access and mapping
              </h6>
            </div>
            <div className="m-2 p-2">
              <h6 className="font-med">- Single App – all sources​</h6>
              <h6 className="font-reg">
                Integrate with your LOS – FinnOne, Lasersoft, Servosys, BYOK,
                etc.
              </h6>
            </div>
            <div className="m-2 p-2">
              <h6 className="font-med">- End-to-end workflow​​</h6>
              <h6 className="font-reg">
                Covers all aspects of RCU process including FIR/Police action
              </h6>
            </div>
            <div className="m-2 p-2">
              <h6 className="font-med">- Scalable Architecture​​</h6>
              <h6 className="font-reg">
              Architecture for high volumes and velocity.
              </h6>
            </div>
          </div>
          <div className="col-md-4">
            <img src={featurescase} class="img-fluid rounded-start" alt="..." />
          </div>
        </div>
        </div>
      </div>

    
      <Footer />
    </>
  )
}

export default RCU
