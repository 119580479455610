import React from 'react'
import Breadcrupms from '../../Breadcrupms/Breadcrupms'
import Footer from '../../Footer/Footer'
import Navbar2 from '../../Navbar/Navbar2'
import introduce from '../../../Assests/Images/introduce.png'
import verification from '../../../Assests/Images/verification.png'

function Qverify() {
  return (
    <>
      <Navbar2 />
      <Breadcrupms title="Q-Verifi" />
      <div className="container ">
        <div class="card row align-items-center p-5 border-0">
          <div className="d-lg-flex justify-content-center">
            <div className="col-lg-2 col-md-4 col-7 me-4 ms-3 ">
              <img src={introduce} class="img-fluid m-auto w-100" alt="" />
            </div>
            <div className="col-lg-8">
              <h5 className="font-reg">
                <b>
                  Introducing the most advanced solution for agency
                  Interactions!
                </b>
              </h5>
              <h5 className="text-justify font-reg">
                Our product revolutionizes the way you verify your clients
                details through agency, allowing you to make quick and accurate
                decisions. With our platform and rule engine, you can allocate
                cases with ease and determine the correct agency based on your
                clients' PIN code and agency mapping details.
              </h5>
            </div>
          </div>
          <div className="d-lg-flex mt-3 justify-content-center  mt-3">
            <div className="col-lg-8 ps-lg-5 ms-lg-5 ">
              <h5 className="text-justify mt-3 font-reg">
                At the center of our product is the platform, which acts as a
                centralized hub for all your agency verification needs. With its
                user-friendly interface, you can easily allocate cases and
                monitor the progress of each verification. And with our powerful
                rule engine, you can automate the verification process and
                ensure that each case is handled accurately and efficiently.
              </h5>
            </div>
            <div className="col-lg-3 col-md-4 col-7">
              <img src={verification} class="img-fluid m-auto w-100" alt="" />
            </div>
          </div>
          <div className="d-lg-flex justify-content-center mt-3">
            <div className="col-lg-10 ">
              <h5 className="text-justify mt-1 font-reg">
                At the center of our product is the platform, which acts as a
                centralized hub for all your agency verification needs. With its
                user-friendly interface, you can easily allocate cases and
                monitor the progress of each verification. And with our powerful
                rule engine, you can automate the verification process and
                ensure that each case is handled accurately and efficiently.
              </h5>
            </div>
          </div>
          <div className="d-lg-flex justify-content-center mt-3 ">
            <div className="col-lg-10 ">
              <h5 className="mt-1 font-reg">
                <b>So why wait?</b>
                <br />
                Start streamlining your agency verification process and
                maximizing your efficiency today with our advanced banking
                agency verification product. With its powerful platform, rule
                engine, and flexible design, our solution is the perfect
                solution for your business.
              </h5>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  )
}

export default Qverify
