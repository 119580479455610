import React from "react";

export default function Privacy() {
  const style = {
    fontSize: "14px",
  };
  return (
    <>
      <div className="container p-2">
        <div className="section-header font-semi mt-5">
          <h2>Privacy Policy</h2>
        </div>
        <div className="outerBox p-2">
          <p className=" font-bold" style={style}>
            1. Introduction
          </p>
          <p style={style}>
            At QUANTAFIC BUSINESS SOLUTIONS PVT LTD ("we," "us," or "our"), we
            are committed to protecting your privacy and ensuring that your
            personal information is handled in a safe and responsible manner.
            This Privacy Policy outlines how we collect, use, store, and protect
            your personal data when you interact with our website.
          </p>
          <p style={style}>
            By submitting your personal information through our website, you
            agree to the terms of this Privacy Policy.
          </p>
          <p className=" font-bold" style={style}>
            2. Information We Collect
          </p>
          <p style={style}>
            When you contact us through our website or request a product demo,
            we may collect the following personal information:
          </p>
          <ul style={style}>
            <li style={style}>Full Name</li>
            <li style={style}>Company Name</li>
            <li style={style}>Job Title</li>
            <li style={style}>Email Address</li>
            <li style={style}>Phone Number</li>
            <li style={style}>
              Any other details you provide in the message section of the form
            </li>
          </ul>
          <p style={style}>
            We do not collect sensitive personal data like financial information
            or personal identification numbers unless specifically required for
            business purposes, in compliance with applicable laws.
          </p>
          <p className=" font-bold" style={style}>
            3. How We Use Your Information
          </p>
          <p style={style}>
            We collect and use your personal data only for the following
            purposes:
          </p>
          <ul style={style}>
            <li style={style}>
              To respond to your inquiries or requests for product demos
            </li>
            <li style={style}>
              To provide you with further information about our products and
              services
            </li>
            <li style={style}>
              To follow up with additional details based on your expressed
              interest in our offerings
            </li>
          </ul>
          <p style={style}>
            We do not use your information for marketing purposes unless you
            explicitly consent to such use. Your personal data will not be sold,
            rented, or shared with any third parties for unrelated business
            purposes.
          </p>
          <p className=" font-bold" style={style}>
            4. Data Storage and Retention
          </p>
          <p style={style}>
            Your personal data is stored securely in our company systems and
            protected with appropriate technical and organizational measures. We
            retain this data only for as long as necessary to fulfill the
            purposes for which it was collected, or as required by law.
          </p>
          <p style={style}>
            Once the data is no longer necessary for these purposes, it will be
            securely deleted or anonymized.
          </p>
          <p className=" font-bold" style={style}>
            5. Third-Party Sharing
          </p>
          <p style={style}>
            We will not share your personal information with any third parties,
            except in the following situations:
          </p>
          <ul style={style}>
            <li style={style}>
              To service providers or partners who assist us in delivering our
              services (e.g., hosting or technical support), under strict
              contractual obligations ensuring data security.
            </li>
            <li style={style}>
              When required by law, legal process, or government authorities.
            </li>
          </ul>
          <p style={style}>
            All data sharing will comply with applicable Indian laws, including
            the Information Technology Act, 2000.
          </p>
          <p className=" font-bold" style={style}>
            6. Your Rights
          </p>
          <p style={style}>
            In accordance with applicable data protection laws, you have the
            right to:
          </p>
          <ul style={style}>
            <li style={style}>Access the personal data we hold about you.</li>
            <li style={style}>
              Request correction or update of your personal information.
            </li>
            <li style={style}>
              Request deletion of your personal information, subject to legal
              requirements.
            </li>
            <li style={style}>
              Withdraw your consent for data processing at any time, without
              affecting the lawfulness of prior processing.
            </li>
          </ul>
          <p style={style}>
            To exercise these rights, or if you have any questions about how we
            handle your personal information, you may contact us at
            [privacy@quantafic.com].
          </p>
          <p className=" font-bold" style={style}>
            7. Data Security
          </p>
          <p style={style}>
            We employ a range of security measures to protect your personal data
            from unauthorized access, alteration, disclosure, or destruction.
            While we strive to use commercially acceptable means to protect your
            personal data, we cannot guarantee its absolute security due to the
            inherent risks of using the internet.
          </p>
          <p className=" font-bold" style={style}>
            8. Cookies and Tracking
          </p>
          <p style={style}>
            Our website may use cookies or similar tracking technologies to
            enhance user experience and analyze web traffic. These cookies do
            not collect personal information unless you voluntarily provide it.
            You can control your cookie preferences through your browser
            settings.
          </p>
          <p className=" font-bold" style={style}>
            9. Legal Basis for Processing
          </p>
          <p style={style}>
            We process your personal data based on legitimate business
            interests, such as responding to your inquiries, fulfilling your
            requests, and maintaining the security and functionality of our
            website.
          </p>
          <p className=" font-bold" style={style}>
            10. Changes to This Policy
          </p>
          <p style={style}>
            We may update this Privacy Policy from time to time in response to
            legal or operational changes. Any updates will be reflected here,
            and the effective date will be updated at the top of the policy.
          </p>
          <p className=" font-bold" style={style}>
            11. Contact Us
          </p>
          <p style={style}>
            If you have any questions or concerns about this Privacy Policy or
            your personal data, please contact us at:
          </p>
          <p style={style}>
            QUANTAFIC BUSINESS SOLUTIONS PVT LTD <br />
            Office 319, Building No 2, Sector 1, Millennium Business Park (MBP)
            Mahape, Navi Mumbai:-400710, Maharashtra <br />
            privacy@quantafic.com <br />
            (+91) 22 4015 8439
          </p>
        </div>
      </div>
    </>
  );
}
