import React from 'react'
import feat2 from '../../Assests/Images/qverifi/feat2.png'
import feat1 from '../../Assests/Images/qverifi/feat1.png'
import feat3 from '../../Assests/Images/qverifi/feat3.png'
import features from '../../Assests/Images/qverifi/features.png'

const Features = () => {
  return (
    <>
      <div className="container p-2">
        <div className="section-header font-semi mt-5">
          <h2>Features</h2>
        </div>
        <div className="outerBox">
          <div className="justify-content-around ">
            <div className="right m-auto ">
              <img src={features} className="img-fluid" alt="" />
            </div>
            <div className="left m-auto mt-4">
              <h4 className="font-med text-center">
                Q-Verifi revolutionizes the way you verify your clients details
                through agency, allowing you to make quick and accurate
                decisions.{' '}
              </h4>
              <div className="col-lg-12 d-lg-flex mt-5 ">
                <div className="col-lg-4 col-md-8  m-auto mt-2 mb-3 p-3">
                  <div className="col-lg-3 col-4 m-auto">
                    <img src={feat1} className=" img-fluid" alt="" />
                  </div>
                  <h5 className="font-reg text-center">
                    Digitalise various verification processes involving agencies
                    during loan life cycle
                  </h5>
                </div>
                <div className="col-lg-4 col-md-8  m-auto mt-2 mb-3 p-3">
                  <div className="col-lg-3 col-4 m-auto">
                    <img src={feat3} className="img-fluid" alt="" />
                  </div>
                  <h5 className="font-reg text-center">
                    Assign, Re-Assign, Pullback functionalities for credit
                    managers to manage Agency work. 
                  </h5>
                </div>
                <div className="col-lg-4 col-md-8  m-auto mt-2 mb-3 p-3">
                  <div className="col-lg-3 col-4 m-auto">
                    <img src={feat2} className="img-fluid" alt="" />
                  </div>
                  <h5 className="font-reg text-center">
                  Integration with LOS for instant allocation of cases to agencies. Mobile app to verifier. 
                  </h5>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default Features
