import React from 'react'
import Breadcrupms from '../Breadcrupms/Breadcrupms'
import Footer from '../Footer/Footer'
import Navbar2 from '../Navbar/Navbar2'
import SachinK from '../../Assests/Images/management/SachinK.webp'
import SunilK from '../../Assests/Images/management/SunilK.webp'
import AjitaM from '../../Assests/Images/management/AjitaM.webp'
import AjeetM from '../../Assests/Images/management/AjeetM.webp'

function Team() {
  return (
    <>
      <Navbar2/>
      <Breadcrupms title='Team'/>
        <div className="conatiner p-5">
        <div class="row d-md-flex justify-content-center">
        {/* <!-- Member 1 --> */}
        <div class="col-md-2 col-7 m-3">
          <div class="card border" >
            <img src={SachinK} class="img-fluid card-img-top" alt="..."/>
            <div class="card-body">
              <h6 class="card-title text-center">Sachin Kulkarni</h6>
            </div>
          </div>
        </div>
        {/* <!-- Member 2  --> */}
        <div class="col-md-2 col-7 m-3">
          <div class="card border" >
            <img src={SunilK} class="img-fluid card-img-top" alt="..."/>
            <div class="card-body">
              <h6 class="card-title text-center">Sunil Karande</h6>
            </div>
          </div>
        </div>
        {/* <!-- Member 3  --> */}
        <div class="col-md-2 col-7 m-3">
          <div class="card border" >
            <img src={AjitaM} class="img-fluid card-img-top" alt="..."/>
            <div class="card-body">
              <h6 class="card-title text-center">Ajita Mohapatra</h6>
            </div>
          </div>
        </div>
        {/* <!-- Member 4  --> */}
        <div class="col-md-2 col-7 m-3">
          <div class="card border" >
            <img src={AjeetM} class="img-fluid card-img-top" alt="..."/>
            <div class="card-body">
              <h6 class="card-title text-center">Ajeet Mishra</h6>
            </div>
          </div>
        </div>
      </div>
        </div>
      <Footer/>
    </>
  )
}

export default Team
