import React from 'react'
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom'
import Career from '../Career/Career.js'
import Home from '../Home.js'
import Products from '../Products/Products.js'
import ViewCard from '../Career/ViewCard.js'
import Apply from '../Form/Apply.js'
import ApiCorn from '../Offerings/ApiCorn/ApiCorn.js'
import DataAnalytics from '../Offerings/DataAnalytics/DataAnalytics.js'
import DigitalAssets from '../Offerings/DigitalAssets/DigitalAssets.js'
import MobApp from '../Offerings/MobApp/MobApp.js'
import OCR from '../Offerings/OCR/OCR.js'
import ResAug from '../Offerings/ResAug/ResAug.js'
import DocMan from '../Offerings/Doc_man/DocMan.js'
import DigitalOnboarding from '../CaseStudies/DigitalOnboarding/DigitalOnboarding.js'
import BureauCon from '../CaseStudies/BureauCon/BureauCon.js'
import Qrulz from '../CaseStudies/QRulz/Qrulz.js'
import Verify from '../CaseStudies/Verify/Verify.js'
import AboutUs from '../AboutUs/AboutUs.js'
import Team from '../Team/Team.js'
import Recognition from '../Recognition/Recognition.js'
import Gallery from '../Gallery/Gallery.js'
import Qcam from '../Products/QCam/Qcam.js'
import Qverify from '../Products/Qverify/Qverify.js'
import QMandate from '../Products/Qmandate/QMandate.js'
import ReachUs from '../ReachUs/ReachUs.js'
import BespokeSoftDev from '../Offerings/SoftwareDevelopment/BespokeSoftDev.js'
import XPenz from '../Products/XPenz/XPenz.jsx'
import RCU from '../CaseStudies/Verify/RCU.js'
import Privacy from '../Footer/Privacy.jsx'



export default function AppRoutes() {
  return (
    <>
      <Routes>
        <Route exact path='/' element={<Home/>}/>
        <Route exact path='/products' element={<Products/>} />
        <Route exact path='/career' element={<Career/>} />
        <Route exact path='/viewcard' element={<ViewCard/>} />
        <Route exact path='/apply' element={<Apply/>} />
        <Route exact path='/apicorn' element={<ApiCorn/>} />
        <Route exact path='/dataAnalytics' element={<DataAnalytics/>} />
        <Route exact path='/digitalassets' element={<DigitalAssets/>} />
        <Route exact path='/mobApp' element={<MobApp/>} />
        <Route exact path='/OCR' element={<OCR/>} />
        <Route exact path='/resaug' element={<ResAug/>} />
        <Route exact path='/docMan' element={<DocMan/>} />
        <Route exact path='/digitalOnboarding' element={<DigitalOnboarding/>} />
        <Route exact path='/bureauCon' element={<BureauCon/>}/>
        <Route exact path='/qrulz' element={<Qrulz/>}/>
        <Route exact path='/verify' element={<Verify/>}/>
        <Route exact path='/about' element={<AboutUs/>}/>
        {/* <Route exact path='/team' element={<Team/>}/> */}
        <Route exact path='/recognition' element={<Recognition/>}/>
        <Route exact path='/gallery' element={<Gallery/>}/>
        <Route exact path='/qcam' element={<Qcam/>}/>
        <Route exact path='/qverifi' element={<Qverify/>}/>
        <Route exact path='/mandate' element={<QMandate/>}/>
        <Route exact path='/reachus' element={<ReachUs/>}/>
        <Route exact path='/xpenz' element={<XPenz/>} />
        <Route exact path='/softdevelop' element={<BespokeSoftDev/>}/>
        <Route exact path='/rcu' element={<RCU/>}/>
        <Route exact path='/privacy' element={<Privacy/>}/>

      </Routes>
    </>
  )
}

