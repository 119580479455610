import React from 'react'
// import img from '.././../Assests/Images/Patterns.png'
import '../Breadcrupms/breacrupms.css'


export default function Breadcrupms(props) {
  return (
    <>
        <div className="breadcrumbs d-flex align-items-center mb-5">
             <div className="container position-relative d-flex flex-column align-items-center" data-aos="fade">
                <h2 className='dark-blue font-semi'>{props.title}</h2>
              </div>
        </div>
    </>
  )
}
