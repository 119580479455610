import React, { useEffect } from 'react'
import './career.css'
import { Link, Navigate, useLocation, useNavigate, useParams } from 'react-router-dom'
import './CareerData'
import Navbar2 from '../Navbar/Navbar2';
import Footer from '../Footer/Footer';
import Breadcrupms from '../Breadcrupms/Breadcrupms';
import Career from './Career'



export default function ViewCard(props) {

    const navigate = useNavigate()

    const { state  } = useLocation();

    useEffect(()=>{

      console.log(state)
    },[])

    const {
        jobId,
        location,
        experience,
        overview,
        position,
        Responsibilites,
        Technical,
        Selection
        // jobObj
      } = props

     const goToApply=()=>{
        console.log(props);
        navigate('/apply' , {state :{params : state} })
     }


  return (
    <>
    <Navbar2/>
    <Breadcrupms/>
    {/* //Add Code to go Back (BackButton) */}
     <div className='container'>
        <div className="careercard m-auto p-5 shadow-lg">
            <div className="container p-5">
                <div className="d-md-flex justify-content-between align-items-center">
                    <h2>{state.params.position}</h2>;
                </div>
                <div className="d-lg-flex align-items-center">
                <div className="content_resp flex mt-5 ">
                    <h5 className='font-med'> Job Overview </h5>
                    <h6 className='font-reg mt-3'>{state.params.overview} </h6>
                </div>
                <div className=" content flex mt-5">
                    <h5 className='font-med'> Technical  and Professional Requirements </h5>
                    <h6 className='font-reg mt-3'>{state.params.technical} </h6>
                </div>
                </div>
                <div className="d-lg-flex align-items-center">
                <div className="content_resp flex mt-5 ">
                    <h5 className='font-med'> Responsibilites </h5>
                    <h6 className='font-reg mt-3'>{state.params.responsibilites} </h6>
                </div>
                <div className=" content flex mt-5">
                    <h5 className='font-med'> Selection Process </h5>
                    <h6 className='font-reg mt-3'>{state.params.selection} </h6>
                </div>
                </div>
                <div className="d-md-flex justify-content-between align-items-center">
                    <div className="d-lg-flex align-items-center">
                    <div className="content_exp flex mt-5 ">
                        <h5 className='font-med'> Experience Required </h5>
                        <h6 className='font-reg mt-3'>{state.params.experience} </h6>
                    </div>
                    <div className=" content flex mt-5">
                        <h5 className='font-med'> Work Location </h5>
                        <h6 className='font-reg mt-3'>{state.params.location} </h6>
                    </div>
                    </div>
                    <button className="btn shadow mt-5" type="submit"
                     onClick={()=> goToApply()}                   
                     ><h6>Apply</h6>

                    {/* <Link to={'/apply/'+state.params.jobId}><h6>Apply</h6></Link>  */}
                    </button>
                </div>
            </div>
        </div>
     </div>
     <Footer/>
    </>
  )
}
