import React from 'react'
import Breadcrupms from '../Breadcrupms/Breadcrupms'
import Footer from '../Footer/Footer'
import Navbar2 from '../Navbar/Navbar2'
import iso from '../../Assests/Images/recognition/iso-2024.png'
import CIBImage from '../../Assests/Images/recognition/CIB-Image.webp'
import bestcompany from '../../Assests/Images/recognition/best_company_to_work.jpeg'
import dipp from '../../Assests/Images/recognition/DIPP_Recognisition.webp'
import gst from '../../Assests/Images/recognition/GST_appreciation.png'
import nova from '../../Assests/Images/recognition/nova.png'
import hcc from '../../Assests/Images/recognition/hcc1.png'
import nascom from '../../Assests/Images/recognition/nasscom.png'


function Recognition() {
    return (
        <>
            <Navbar2 />
            <Breadcrupms title='Recognition' />
            <div className="container p-5">
                <div class="row justify-content-center ">
                    <div class="col-md-5 col-sm-6 mb-4">
                        <div class="card-item shadow-lg rounded-2">
                            <div class="flex-column">
                                <div class="col-xl-12">
                                    <div class="card-bg rounded-start">
                                        <img class="img-fluid " src={iso}/>
                                    </div>
                                </div>
                                <div class="col-xl-8 d-flex align-items-center">
                                    <div class="card-body p-3">
                                        <h5 class="card-title">ISO Certificate</h5>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row justify-content-center ">
                <div class="col-md-5 col-sm-6 m-4">
                    <div class="card-item shadow-lg rounded-2">
                        <div class="flex-column">
                            <div class="col-xl-12">
                                <div class="card-bg rounded-start">
                                    <img class="img-fluid " src={CIBImage}/>
                                </div>
                            </div>
                            <div class="col-xl-8 d-flex align-items-center">
                                <div class="card-body p-3 ">
                                    <h5 class="card-title">Winner–CIB Bank, Egypt</h5>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-md-5 col-sm-6 m-4">
                    <div class="card-item shadow-lg rounded-2">
                        <div class="flex-column">
                            <div class="col-xl-12">
                                <div class="card-bg rounded-start">
                                    <img class="img-fluid " src={bestcompany}/>
                                </div>
                            </div>
                            <div class="col-xl-7 d-flex align-items-center">
                                <div class="card-body p-3 ">
                                    <h5 class="card-title">Best company to work</h5>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-md-5 col-sm-6 m-4">
                    <div class="card-item shadow-lg rounded-2">
                        <div class="flex-column">
                            <div class="col-xl-12">
                                <div class="card-bg rounded-start">
                                    <img class="img-fluid " src={dipp}/>
                                </div>
                            </div>
                            <div class="col-xl-11 d-flex align-items-center">
                                <div class="card-body p-3 ">
                                    <h5 class="card-title">Startupindia – Ministry of Commerce</h5>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-md-5 col-sm-6 m-4">
                    <div class="card-item shadow-lg rounded-2">
                        <div class="flex-column">
                            <div class="col-xl-12">
                                <div class="card-bg rounded-start">
                                    <img class="img-fluid " src={gst}/>
                                </div>
                            </div>
                            <div class="col-xl-10 d-flex align-items-center">
                                <div class="card-body p-3 ">
                                    <h5 class="card-title">GST Certificate of Appreciation.</h5>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-md-5 col-sm-6 m-4" style={{"height": "100"}}>
                    <div class="card-item shadow-lg rounded-2">
                        <div class="flex-column">
                            <div class="col-xl-12">
                                <div class="card-bg rounded-start">
                                    <img class="img-fluid " src={nova}/>
                                </div>
                            </div>
                            <div class="col-xl-10 d-flex align-items-center">
                                <div class="card-body p-3 ">
                                    <h5 class="card-title">Nova Scotia Business <br/> Inc.</h5>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-md-5 col-sm-6 m-4">
                    <div class="card-item shadow-lg rounded-2">
                        <div class="flex-column">
                            <div class="col-xl-12">
                                <div class="card-bg rounded-start">
                                    <img class="img-fluid " src={hcc}/>
                                </div>
                            </div>
                            <div class="col-xl-10 d-flex align-items-center">
                                <div class="card-body p-3 ">
                                    <h5 class="card-title">Halifax Chamber of <br/> Commerce </h5>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-md-5 col-sm-6 m-4">
                    <div class="card-item shadow-lg rounded-2">
                        <div class="flex-column">
                            <div class="col-xl-12">
                                <div class="card-bg rounded-start">
                                    <img class="img-fluid " src= {nascom} />
                                </div>
                            </div>
                            <div class="col-xl-8 d-flex align-items-center">
                                <div class="card-body p-3 ">
                                    <h5 class="card-title">NASSCOM</h5>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
           </div>
        </div>
            <Footer />
        </>
    )
}

export default Recognition
