import React from "react";
import "../Testimonials/testimonial.css";
import rajendra from "../../Assests/Images/rajendra.png";
import satish from "../../Assests/Images/satish.png";
import certificate from "../../Assests/Images/Certificate.jpg";
import { FaStar } from "react-icons/fa";

function Testimonials() {
  return (
    <>
      <div className="section-header font-semi mt-5">
        <h2>Testimonials</h2>
      </div>
      <div className="testimonials">
        <div className="container p-5 ">
          <div class="slides-2 swiper ">
            <div class="swiper-wrapper ">
              <div class="swiper-slide ">
                <div class="testimonial-wrap">
                  <div class="testimonial-item">
                    <div class="d-flex">
                      <img
                        src={rajendra}
                        class="img-fluid testimonial-img"
                        alt=""
                      />
                      <div class="col-md-10">
                        <h3>Rajendra Bisht</h3>
                        <h6 class="grey">
                          Vice President - Technology & Digital at Bajaj Finance
                          Ltd. - Auto Finance
                        </h6>
                        <div class="d-flex stars mt-5 ms-4">
                          <FaStar style={{ color: "#FFFF00" }} />
                          <FaStar style={{ color: "#FFFF00" }} />
                          <FaStar style={{ color: "#FFFF00" }} />
                          <FaStar style={{ color: "#FFFF00" }} />
                          <FaStar style={{ color: "#FFFF00" }} />
                        </div>
                      </div>
                    </div>

                    <div>
                      <h6 className="font-reg">
                        <p>
                          I have been an staunch believer of
                          customer/partnership relationship. It’s a relation of
                          trust, confidence, mutual understanding and win-win
                          construct.
                        </p>
                        <p>
                          Quantafic Business Solutions led by its able
                          leadership team Sachin Kulkarni , Sunil Karande and
                          Ajeet are doing a fabulous work in helping clients
                          like us to transform our businesses using cutting edge
                          Technologies
                        </p>
                        <p>
                          It was actually our previlige and honor to visit your
                          office to acknowledge, appreciate and felicitate all
                          star performers who are toiling hard and sometimes
                          burn midnight oils to support us.
                        </p>
                        <p>Gratitude and many thanks to team Quantafic</p>
                      </h6>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="testimonials">
        <div className="container p-5 ">
          <div class="slides-2 swiper ">
            <div class="swiper-wrapper ">
              <div class="swiper-slide ">
                <div class="testimonial-wrap">
                  <div class="testimonial-item">
                    <div class="d-flex">
                      <img
                        src={satish}
                        class="img-fluid testimonial-img"
                        alt=""
                      />
                      <div class="col-md-10">
                        <h3>Satish Jha</h3>
                        <h6 class="grey">
                          Head - Process Innovation, PAR & Securitization unit at Aditya Birla Finance Ltd
                        </h6>
                        <div class="d-flex stars mt-5 ms-4">
                          <FaStar style={{ color: "#FFFF00" }} />
                          <FaStar style={{ color: "#FFFF00" }} />
                          <FaStar style={{ color: "#FFFF00" }} />
                          <FaStar style={{ color: "#FFFF00" }} />
                          <FaStar style={{ color: "#FFFF00" }} />
                        </div>
                      </div>
                    </div>

                    <div>
                      <h6 className="font-reg">
                        <p>
                          QVerify has proved to be the core of solving all the
                          verification related issues and because of this only
                          we have been able to achieve 75% of TAT on FI closure
                          in less than 4hr
                        </p>
                        <p>
                          Kudos to the entire team of Q verify for helping us
                          build such great solutions
                        </p>
                      </h6>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="testimonials">
        <div className="container p-5 ">
          <div className="testimonial-wrap">
            <div className="testimonial-item ">
              <div className=" d-grid justify-items-center">
                <div className="mx-auto">
                  <h5>Work Recognition Certificate</h5>
                </div>

               
              

                  <img src={certificate} className="img-fluid w-50 mx-auto" alt="" />
                 
               
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Testimonials;
