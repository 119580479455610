import React from 'react'
import ScheduleButton from './ScheduleButton'
import '../ScheduleButton/schedule.css'
import { useForm } from 'react-hook-form'
import { useState } from 'react'
import axios from 'axios'

const ScheduleModal = () => {

  const {register, handleSubmit, formState:{errors}} = useForm();
  const [firstName , setFirstName] = useState();
  const [lastName , setLastName] = useState();
  const [email , setEmail] = useState();
  const [number , setNumber] = useState();


  const applicationOptions = {
    firstName: { required: 'Name is Required' },
    LastName: { required: 'Name is Required' },
    email: {
      required: 'Email is required',
      pattern: {
        value: /^\S+@\S+$/i,
        message: 'This is not valid email',
      },
    },
    number: {
      required: 'Mobile number is Required',
      pattern: {
        value: /^([+]?[\s0-9]+)?(\d{3}|[(]?[0-9]+[)])?([-]?[\s]?[0-9])+$/i,
        message: 'This is not a valid Number',
      },
      maxLength: {
        value: 10,
        message: "Number can't contain more than 10 digits",
      },
      minLength: {
        value: 10,
        message: 'Number must contain 10 digits',
      },
    },
  }

  const handleError = (errors) => {}
  const handleForm = (data) => {

    axios
    .post('https://leaves.quantafic.in/api/qbs/contact', {
      headers : {
        'Content-Type' : 'application/json',
        'Cache-Control': 'no-cache',
        'Access-Control-Allow-Origin': '*',
        'Access-Control-Allow-Methods': 'POST, GET',
        'Access-Control-Allow-Headers ': " Origin, Content-Type, Accept, Authorization",
        // "Referrer-Policy": 'origin',
        // 'Access-Control-Allow-Credentials' : 'true',
        // 'crossdomain': 'true',
        // 'mode': 'no-cors'
      },
      body: {
        firstName: firstName,
        lastName: lastName,
        mobNo: number,
        emailId: email
      },
    })
    .then((response) => {
      if (response.status === 200) {
        setFirstName('')
        setLastName('')
        setEmail('')
        setNumber('')
        alert("Thank you for contacting Quantafic Our team will reach you via email/phone")
      } else {
        alert('Some error occured')
      }
      console.log(response.data.respBody.resp)
    })

  }

//   ------------------------------POST REQ----------------------------------------



    
  return (
    <>
      <ScheduleButton  />
      <div
        class="modal fade"
        id="exampleModal"
        tabIndex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
        handleclose ='false'
       
      >
         <form onSubmit={handleSubmit(handleForm, handleError)}>
             <div class="modal-dialog modal-dialog-centered">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title" id="exampleModalLabel">
                To schedule a demo , please fill up the details.
              </h5>
              <button
                type="button"
                class="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div class="modal-body m-auto">
              <div className="ReachField_auto ">
                <label className="font-reg p-0">First Name</label>
                <input
                  className="form-control font-reg"
                  type='text'
                  name="firstName"
                  value={firstName}
                  placeholder="First Name"
                  {...register('firstName', applicationOptions.firstName)}
                  onChange={(e) => setFirstName(e.target.value)}
                ></input>
                <small className="text-danger">
                    {errors?.firstName && errors.firstName.message}
                  </small>
              </div>
              <div className="ReachField_auto ">
                <label className="font-reg p-0">Last Name</label>
                <input
                  className="form-control font-reg"
                  type="text"
                  name="lastName"
                  value={lastName}
                  placeholder="Last Name"
                  {...register('lastName', applicationOptions.LastName)}
                  onChange={(e) => setLastName(e.target.value)}
                ></input>
                <small className="text-danger">
                    {errors?.lastName && errors.lastName.message}
                  </small>
              </div>
              <div className="ReachField_auto ">
                <label className="font-reg p-0">Email Address</label>
                <input
                  className="form-control font-reg"
                  type="email"
                  name="email"
                  value={email}
                  placeholder="Email Address"
                  {...register('email', applicationOptions.email)}
                  onChange={(e) => setEmail(e.target.value)}
                ></input>
                <small className="text-danger">
                    {errors?.email && errors.email.message}
                  </small>
              </div>
              <div className="ReachField_auto ">
                <label className="font-reg p-0">Mobile Number</label>
                <input
                  className="form-control font-reg"
                  type="tel"
                  name="number"
                  value={number}
                  placeholder="Mobile Number"
                  {...register('number', applicationOptions.number)}
                  onChange={(e) => setNumber(e.target.value)}
                ></input>
                <small className="text-danger">
                    {errors?.number && errors.number.message}
                  </small>
              </div>
            </div>
            <div class="modal-footer">
              <button type="button" class="outside-btn" data-bs-dismiss="modal">
                <h6>Cancel</h6>
              </button>
              <button type="submit" class="btn" data-bs-dismiss="modal">
                <h6>Save</h6>
              </button>
            </div>
          </div>
        </div> 
         </form>
      
      </div>
    </>
  )
}

export default ScheduleModal
