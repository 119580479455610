import React, { useState } from "react";
import "../Navbar/nav.css";
import { Link } from "react-router-dom";


export default function Navbar() {
  // Change Navbar Color, logo
  const [change, setchange] = useState(false);
  const changelements = () => {
    if (window.scrollY >= 600) {
      setchange(true);
    } else {
      setchange(false);
    }
  };
  window.addEventListener("scroll", changelements);

  //dropdowm on hover
  // $(document).ready(function(){
  //   $('.dropdown').hover(function(){
  //     var dropdowmMenu = $(this).children('.dropdown-menu')
  //     if(dropdowmMenu.is(':visible')){
  //       dropdowmMenu.parent().toggleClass('open')
  //     }
  //   })
  // })

  return (
    <>
      <nav
        className={
          change
            ? "navbar navbar_scrolled navbar-expand-lg"
            : "navbar navbar_scrolled navbar-expand-lg" 
        }
      >
        <div className="container-fluid">
          <Link
            className={
              change ? "navbar-brand navbar-brand_scrolled" : "navbar-brand navbar-brand_scrolled"
            }
            to="/"
          ></Link>
          <button
            className="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <div className="icon"><span className="navbar-toggler-icon "></span></div>
            
          </button>
          <div className="collapse navbar-collapse nav-back-col navCollapse" id="navbarSupportedContent">
            <ul className="navbar-nav ms-auto mb-2 mb-lg-0 align-items-center">
            <div className="mavContents d-lg-flex">
                <li className="nav-item">
                  <Link className={change ? "nav-link nav-link_scrolled active text-center" : "nav-link nav-link_scrolled active text-center"} aria-current="page" to="/">
                    <h6 className="font-reg">Home</h6>
                  </Link>
                </li>
                <li className="nav-item dropdown">
                  <Link
                    className={change ? "nav-link nav-link_scrolled active text-center" : "nav-link nav-link_scrolled active text-center"}
                    to=""
                    role="button"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    <h6 className="font-reg">Company</h6>
                  </Link>
                  <ul className="dropdown-menu">
                    <li>
                      <Link className="dropdown-item" to="/about"> <h6 className="font-reg">About Us</h6> </Link>
                    </li>
                    <li>
                      <Link className="dropdown-item" to="/career"> <h6 className="font-reg">Career</h6> </Link>
                    </li>
                    {/* <li>
                      <Link className="dropdown-item" to="/team"> <h6 className="font-reg">Team</h6> </Link>
                    </li> */}
                    <li>
                      <Link className="dropdown-item" to="/recognition"> <h6 className="font-reg">Recognition</h6> </Link>
                    </li>
                    <li>
                      <Link className="dropdown-item" to="/gallery"> <h6 className="font-reg">Gallery</h6> </Link>
                    </li>
                  </ul>
                </li>
                <li className="nav-item dropdown">
                  <Link
                    className={change ? "nav-link nav-link_scrolled active text-center" : "nav-link nav-link_scrolled active text-center"}
                    to=""
                    role="button"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    <h6 className="font-reg">Products</h6>
                  </Link>
                  <ul className="dropdown-menu">
                    <li>
                      <Link className="dropdown-item" to="/qverifi"> <h6 className="font-reg">Q-Verifi</h6> </Link>
                    </li>
                    <li>
                      <Link className="dropdown-item" to="/mandate"> <h6 className="font-reg">Q-Mandate</h6> </Link>
                    </li>
                    <li>
                      <Link className="dropdown-item" to="/qrulz"> <h6 className="font-reg">Q-Rulez</h6> </Link>
                    </li>
                    <li>
                      <Link className="dropdown-item" to="/qcam"> <h6 className="font-reg">Q-CAM</h6> </Link>
                    </li>
                    <li>
                      <Link className="dropdown-item" to="/bureauCon"> <h6 className="font-reg">Q-Connect</h6> </Link>
                    </li>
                    <li>
                      <Link className="dropdown-item" to="/OCR"> <h6 className="font-reg">Q-Scan.AI</h6> </Link>
                    </li>
                    <li>
                      <Link className="dropdown-item" to="/xpenz"> <h6 className="font-reg">XPenz</h6> </Link>
                    </li>
                    <li>
                      <Link className="dropdown-item" to="/apicorn"> <h6 className="font-reg">Apicorn</h6> </Link>
                    </li>
                    <li>
                      <Link className="dropdown-item" to="/docMan"> <h6 className="font-reg">Document Management</h6> </Link>
                    </li>
                  </ul>
                </li>
                <li className="nav-item dropdown me-3 pe-4">
                  <a
                    className={change ? "nav-link nav-link_scrolled active text-center" : "nav-link nav-link_scrolled active text-center"}
                    to=""
                    role="button"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    <h6 className="font-reg">Services</h6>
                  </a>
                  <ul className="dropdown-menu">
                    <li>
                      <Link className="dropdown-item" to="/dataAnalytics"> <h6 className="font-reg">Data Analytics</h6> </Link>
                    </li>
                    <li>
                      <Link className="dropdown-item" to="/OCR"> <h6 className="font-reg">Q-Scan.ai/Image Processing</h6> </Link>
                    </li>
                    <li>
                      <Link className="dropdown-item" to="/digitalassets"> <h6 className="font-reg">Digital Assets</h6> </Link>
                    </li>
                    <li>
                      <Link className="dropdown-item" to="/mobApp"> <h6 className="font-reg">Mobile Applications</h6> </Link>
                    </li>
                    <li>
                      <Link className="dropdown-item" to="/resaug"> <h6 className="font-reg">Resource Augmentation</h6> </Link>
                    </li>
                    <li>
                      <Link className="dropdown-item" to="/softdevelop"> <h6 className="font-reg">Bespoke software <br/> development</h6> </Link>
                    </li>
                  </ul>
                </li>
            </div>

            <form className="d-flex" role="search">
              <Link to='/reachus'><button className="btn shadow" type="submit">
                 <h6>Reach Us</h6> 
              </button></Link>
            </form>
            </ul>
          </div>
        </div>
      </nav>
    </>
  );
}
