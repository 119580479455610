import React from 'react'
import Breadcrupms from '../../Breadcrupms/Breadcrupms'
import Footer from '../../Footer/Footer'
import Navbar2 from '../../Navbar/Navbar2'
import '../DigitalAssets/digital.css'
import A1 from '../../../Assests/Images/digital_assets/A1.png'
import A2 from '../../../Assests/Images/digital_assets/A2.png'
import A3 from '../../../Assests/Images/digital_assets/A3.png'
import A4 from '../../../Assests/Images/digital_assets/A4.png'
import A5 from '../../../Assests/Images/digital_assets/A5.png'
import A6 from '../../../Assests/Images/digital_assets/A6.png'
import A7 from '../../../Assests/Images/digital_assets/A7.png'
import A8 from '../../../Assests/Images/digital_assets/A8.png'
import goals1 from '../../../Assests/Images/digital_assets/goals1.png'
import goals2 from '../../../Assests/Images/digital_assets/goals2.png'

function DigitalAssets() {
  return (
    <>
      <Navbar2/>
      <Breadcrupms title='Digital Assets'/>
        <div className="container">
        <div class="col-lg-11 m-5">
          <h5 class="font-med">
            Quantafic Embarks on readily available library of Assets for Quick,
            Efficient and Effective Digital Transformation.
          </h5>
        </div>
        <div class="card align-items-center border-0">
          <div class="digital d-flex flex-column col-lg-12">
            <div class="d-flex flex-row justify-content-center">
              <img
                src={A1}
                class="img-fluid m-1"
                alt="..."
              />
              <img
                src={A2}
                class="img-fluid m-1"
                alt="..."
              />
              <img
                src={A3}
                class="img-fluid m-1"
                alt="..."
              />
              <img
                src={A4}
                class="img-fluid m-1"
                alt="..."
              />
            </div>
            <div class="d-flex flex-row justify-content-center mt-3">
              <img
                src={A5}
                class="img-fluid m-1"
                alt="..."
              />
              <img
                src={A6}
                class="img-fluid m-1"
                alt="..."
              />
              <img
                src={A7}
                class="img-fluid m-1"
                alt="..."
              />
              <img
                src={A8}
                class="img-fluid m-1"
                alt="..."
              />
            </div>
          </div>
          <div class="card mt-5 border-0">
          <div class="row g-0 mb-3 border rounded-2 justify-content-center">
            <div class="col-md-8 mt-4">
              <div class="card-body">
                <h5 class="card-title Metro-Regular ">Digital Transformation will be successful only if it is able
                  to achieve any one of the following goals:</h5>
                  <h6 class="font-reg card-title"><i class="fas fa-circle"></i>-Reduce Operational Cost</h6>
                  <h6 class="font-reg card-title"><i class="fas fa-circle"></i>-Reduce Processing Time</h6>
                  <h6 class="font-reg card-title"><i class="fas fa-circle"></i>-Ease of doing Business</h6>
                  <h6 class="font-reg card-title"><i class="fas fa-circle"></i>-Able to handle large volume</h6>
            </div>
            </div>
            <div class="data">
              <img src={goals1} class="img-fluid rounded-start" alt="..."/>                
            </div>
        </div>
        <div class="row g-0 border rounded-2 justify-content-center">
          <div class="col-md-8 mt-4">
            <div class="card-body">
              <h5 class="card-title Metro-Regular">
                And to achieve these objectives the technology vendor need to
                focus following aspects:
              </h5>
              <h6 class="font-reg card-title"><i class="fas fa-circle"></i>-Understanding of domain</h6>
              <h6 class="font-reg card-title"><i class="fas fa-circle"></i>-Use new technologies</h6>
              <h6 class="font-reg card-title"><i class="fas fa-circle"></i>-Security of application</h6>
              <h6 class="font-reg card-title"><i class="fas fa-circle"></i>-Scalability of application</h6>
            </div>
          </div>
          <div class="data">
            <img src={goals2} class="img-fluid rounded-start" alt="..."/>               
          </div>
      </div>
      </div>
        </div>
        </div>
      <Footer/>
    </>
  )
}

export default DigitalAssets
