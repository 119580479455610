import logo from './logo.svg';
import './App.css';
import AppRoutes from './Components/Routes/AppRoutes';


function App() {
  return (
     <>
      <AppRoutes/>
     </>
  );

 
}

export default App;
