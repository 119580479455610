import React from 'react'
import { Link } from 'react-router-dom'
import '../CaseStudies/caseStudies.css'
// import digiCus from '../../Assests/Images/case-studies/customer_onboarding_1.jpg'
// import Bureau from '../../Assests/Images/case-studies/multi_bureau_1.jpg'
// import qrulz from '../../Assests/Images/case-studies/QRulz.webp'
import qverify from '../../Assests/Images/case-studies/qverify.jpg'
// import digiTrans from '../../Assests/Images/case-studies/digital_transformation_2.jpg'
// import qscan from '../../Assests/Images/case-studies/qscan.jpg'
import case1FI from '../../Assests/Images/case-studies/case1FI.png'
import case2Cold from '../../Assests/Images/case-studies/case2Cold.png'
import case3Rcu from '../../Assests/Images/case-studies/case3Rcu.png'

export default function Offerings() {
  return (
    <>
      <div className="container">
            <div className="section-header font-semi mt-5">
                <h2>Case Studies of Q-Verifi</h2>
            </div>
            {/* <div className="d-md-flex case w-100 justify-content-center pb-5 mt-5">
                <div className="cardImg">
                    <img src={digiCus} class="img-fluid" alt="..."/>
                </div>
                <div className="col-md-5 caseContent">
                    <h4>Digital Customer Onboarding</h4>
                    <h6 className='font-reg'>Quantafic Digital Customer onboarding helped leading Auto Finance Company to improve business by 23%.</h6>
                    <h6><Link to='/digitalOnboarding' class="card-link">Learn More</Link></h6>  
                </div>
            </div>
            <hr/>
            <div  className="d-md-flex case w-100 justify-content-center">
                <div className="cardImg order-md-2">
                    <img src={Bureau} class="img-fluid" alt="..."/>
                </div>
                <div className="col-md-5 order-md-1 caseContent">
                    <h4>Q-Connect</h4>
                    <h6 className='font-reg'>Quantafic Bureau Connect helped leading lender to reduce credit bureau Cost by 70% & 112 Bureau Variables increased quality by 48%.</h6>
                    <h6><Link to='/bureauCon' class="card-link">Learn More</Link></h6>  
                </div>
            </div>
           <hr />
            <div className="d-md-flex case w-100 justify-content-center"> 
                <div className="cardImg p-3">
                    <img src={qrulz} class="img-fluid" alt="..."/>
                </div>
                <div className="col-md-5 caseContent">
                    <h4>Q-Rulez</h4>
                    <h6 className='font-reg'>In memory processing feature, rule engine helped Auto Finance company to sanction loan within 3 minutes.</h6>
                    <h6><Link to='/qrulz' class="card-link">Learn More</Link></h6>  
                </div>
            </div>
            <hr /> */}
            <div  className="d-md-flex case w-100 justify-content-center ">
                <div className="cardImg order-md-2 p-3">
                    <img src={qverify} class="img-fluid" alt="..."/>
                </div>
                <div className="col-md-5 order-md-1 caseContent">
                    {/* <h4>Q-Verifi</h4> */}
                    <h6 className='font-reg'>Leading Pvt Sector Banks digitalized FI, Legal, Technical and PD process by Q-verifi product and achieved 22% business growth and this process automation helped in reducing the response time by 7 hours.</h6>
                    <h6><Link to='/verify' class="card-link">Learn More</Link></h6>  
                </div>
            </div>
           <hr />
            <div  className="d-md-flex case w-100 justify-content-center ">
                <div className="cardImg order-md-1 p-3">
                    <img src={case1FI} class="img-fluid" alt="..."/>
                </div>
                <div className="col-md-5 order-md-2 caseContent">
                    {/* <h4>Q-Verifi</h4> */}
                    <h6 className='font-reg'>A leading NBFC digitalized FI and PD processes using Q-Verifi product and achieved higher business growth, ensured customer delight and improved TAT of FI, PD processes by 10 hours.</h6>
                    <h6><Link to='/verify' class="card-link">Learn More</Link></h6>  
                </div>
            </div>
           <hr />
           <div  className="d-md-flex case w-100 justify-content-center ">
                <div className="cardImg order-md-2 p-3">
                    <img src={case2Cold} class="img-fluid" alt="..."/>
                </div>
                <div className="col-md-5 order-md-1 caseContent">
                    {/* <h4>Q-Verifi</h4> */}
                    <h6 className='font-reg'>The banking regulator implemented Q-Verifi for surveying government approved cold storages across the length and width of India. <br /><br />All FOS of survey agencies used the mobile app of Q-Verifi for seamless real-time data capture.</h6>
                    <h6><Link to='/verify' class="card-link">Learn More</Link></h6>  
                </div>
            </div>
           <hr />
           <div  className="d-md-flex case w-100 justify-content-center ">
                <div className="cardImg order-md-1 p-3">
                    <img src={case3Rcu} class="img-fluid" alt="..."/>
                </div>
                <div className="col-md-5 order-md-2 caseContent">
                    {/* <h4>Q-Verifi</h4> */}
                    <h6 className='font-reg'>Private sector bank migrated all RCU process (such as sampling, seeding, asset cross-check, etc.) from manual/email process to a single platform of Q-Verifi. 
                    <br/><br />This improved the accountability and brought transparency to each process because case-status could be known in real time, TAT could be measured and attributed to each resource.</h6>
                    <h6><Link to='/rcu' class="card-link">Learn More</Link></h6>  
                </div>
            </div>
           {/* <hr />
            <div className="d-md-flex case w-100 justify-content-center">
                <div className="cardImg p-3">
                    <img src={digiTrans} class="img-fluid" alt="..."/>
                </div>
                <div className="col-md-5 caseContent">
                    <h4>Digital Assests</h4>
                    <h6 className='font-reg'>Learn about Quantafic implementations involving Web scrapping, bots, automated workflow, OCR, Android and iOS mobile application, Secured and scalable applications etc.</h6>
                    <h6><Link to='/digitalassets' class="card-link">Learn More</Link></h6>  
                </div>
            </div>
            <hr  />
            <div  className="d-md-flex case w-100 justify-content-center">
                <div className="cardImg order-md-2 p-3">
                    <img src={qscan} class="img-fluid" alt="..."/>
                </div>
                <div className="col-md-5 order-md-1 caseContent">
                    <h4>Q-ScanAI</h4>
                    <h6 className='font-reg'>Q-SCAN build using Image processing and Machine learning helped large conglomerate NBFC to extract data from different types of document Images or PDF files by eliminating the manual data entry.</h6>
                    <h6><Link to='/OCR' class="card-link">Learn More</Link></h6>  
                </div>
            </div> */}
        {/* <div className="d-flex  justify-content-center">
            <div className='view_all'>
                <button className="btn shadow" type="submit">
                        <h5>View All</h5> 
                </button>
            </div>
        </div> */}
      </div>
    </>
  )
}
