import React from 'react'
import Breadcrupms from '../../Breadcrupms/Breadcrupms'
import Footer from '../../Footer/Footer'
import Navbar2 from '../../Navbar/Navbar2'
import XPenz2 from '../../../Assests/Images/qclaim.png'
import XPenz1 from '../../../Assests/Images/qclaim1.png'
import ScanAI from '../../../Assests/Images/qscan_ai/Q-ScanAI.png'

const XPenz = () => {
  return (
    <div>
      <Navbar2 />
      <Breadcrupms title="XPenz" />
      <div className="container ">
        <div class="card row align-items-center p-5 border-0">
          <div className="d-lg-flex justify-content-center">
            <div className="col-lg-2 col-md-4 col-5 me-4  ">
              <img src={XPenz2} class="img-fluid m-auto w-100" alt="" />
            </div>
            <div className="col-lg-7 ms-lg-5 me-xl-3  m-0">
              <h5 className="text-justify mt-5 font-reg">
                <b>XPenz, </b>
                 Quantafic’s digital expense processing application is a
                cloud-based software solution that automates the expense
                management process for businesses. The application is designed
                to help organizations save time and reduce errors associated
                with manual expense management processes.
              </h5>
            </div>
          </div>
          <div className="d-lg-flex mt-5 justify-content-center ">
            <div className="col-lg-7 ">
              <h5 className="text-justify mt-lg-5 font-reg">
              The application provides a streamlined interface that allows employees to easily submit expense reports, receipts, and other documentation from anywhere, at any time. The application can be accessed via desktop or mobile devices, making it convenient for employees who are on-the-go.
              </h5>
            </div>
            <div className="col-lg-2 col-md-4 col-6 ms-lg-5 ms-xl-5 me-lg-5">
              <img src={XPenz1} class="img-fluid m-auto w-100" alt="" />
            </div>
          </div>
          <div className="flex-column justify-content-center mt-5 ">
              <div className="col-lg-10 m-auto ps-lg-3
              ">
                <h5 className="text-justify mt-1 font-reg">
                  XPenz application uses OCR and machine learning algorithms to automatically capture the expense details from image of bills thereby reducing the manual data entry for employees. XPenz allows line manager to review the team member expense claim through mobile application or portal and approve it instantly. This help in quick approval of expense claims and greater employee satisfaction.
                </h5>
              </div>
            <div className="col-md-9 col-sm-8 m-auto mt-3">
              <img src={ScanAI} class="img-fluid m-auto w-100" alt="" />
            </div>
          </div>
          <div className="d-md-flex justify-content-center mt-5">
              <div className="col-lg-10 m-auto ps-lg-3 ">
                  <h5 className="text-justify mt-3 font-reg">
                      Additionally,the application integrates with popular accounting and ERP systems  allowing for seamless integration into existing workflows. This also enables real-time synchronization of data, eliminating the need for manual data entry. 
                    </h5>
              </div>
            </div>
          <div className="d-md-flex justify-content-center mt-5">
              <div className="col-lg-10 m-auto ps-lg-3 ">
                  <h5 className="text-justify mt-2  font-reg">
                  XPenz provides comprehensive  dashboard and reports, including insights into spending trends, expense categories, and other key metrics, providing businesses with the data they need to make informed decisions and manage the cashflow.
                    </h5>
              </div>
            </div>
          <div className="d-md-flex justify-content-center mt-5">
              <div className="col-lg-10 m-auto ps-lg-3  ">
                  <h5 className="text-justify mt-2 font-reg">
                  Overall, Quantafic's digital expense processing application (“ XPenz”) is a comprehensive, efficient, and user-friendly solution that can help businesses automate their expense management process and save time and money in the process
                    </h5>
              </div>
            </div>

        </div>
      </div>
      <Footer />
    </div>
  )
}

export default XPenz
