import React from 'react'
import { useState } from 'react'
import '../ScheduleButton/schedule.css'
import ScheduleModal from './ScheduleModal'

const ScheduleButton = () => {
  const { closeModal, setCloseModal } = useState(false)

  const handleClose = () => {
    setCloseModal(true)
  }

  return (
    <>
      <div className="outside">
        <button
          className="style shadow"
          data-bs-target="#exampleModal"
          data-bs-toggle="modal"
        >
          <h6>Schedule A Demo</h6>
        </button>
      </div>
    </>
  )
}

export default ScheduleButton
