import React from 'react'
import Breadcrupms from '../../Breadcrupms/Breadcrupms'
import Footer from '../../Footer/Footer'
import Navbar2 from '../../Navbar/Navbar2'
import data from '../../../Assests/Images/data_analytics/data_anlaytics.png'
import '../DataAnalytics/datanalytics.css'
import riskanalysis from '../../../Assests/Images/data_analytics/card/risk analysis.webp'
import campaign from '../../../Assests/Images/data_analytics/card/Campaign-Management_.webp'
import descriptive from '../../../Assests/Images/data_analytics/card/Descriptive-and-Predictive-Analytics_.webp'
import closeup from '../../../Assests/Images/data_analytics/card/close-up-phone-scanning-parcel-informations.jpg'
import Business from '../../../Assests/Images/data_analytics/card/Business-Intelligence-_BI__.webp'
import resAug from '../../../Assests/Images/data_analytics/card/resource_aug.webp'
import java from '../../../Assests/Images/data_analytics/technology/JAVA.png'
import dataware from '../../../Assests/Images/data_analytics/technology/Dataware.png'
import power from '../../../Assests/Images/data_analytics/technology/PowerBI.png'
import python from '../../../Assests/Images/data_analytics/technology/Python.png'
import R from '../../../Assests/Images/data_analytics/technology/R.png'
import SAS from '../../../Assests/Images/data_analytics/technology/SAS.png'
import Tab from '../../../Assests/Images/data_analytics/technology/Tableau.png'
function DataAnalytics() {
  return (
    <>
      <Navbar2 />
      <Breadcrupms title="Data Analytics" />
      <div className="container p-5">
        <div class="row justify-content-center">
          <div class="col-lg-8 mt-5 ">
            <h5 class="font-reg">
              <i class="fas fa-dot-circle"></i> Quantafic Data scientists help
              customers data driven decision making.
            </h5>
            <h5 class="font-reg">
              <i class="fas fa-dot-circle"></i> Data Analytics is offered as a
              service wherein we support the BIU team by performing descriptive
              analytics or predictive analytics or by providing skilled
              resources.
            </h5>
            <h5 class="font-reg">
              <i class="fas fa-dot-circle"></i>Customer can benefit from
              Quantafic Data Scientists skills by setting ODC with Quantafic or
              augmenting own team with Quantafic resources operating from own
              premises.
            </h5>
            <h5 class="font-reg">
              <i class="fas fa-dot-circle"></i> Our BI expert help customer in
              representing data in various dashboard and reports.
            </h5>
            <h5 class="font-reg">
              <i class="fas fa-dot-circle"></i> Our expertise of AI and ML help
              in building complex predictive models and python and java skills
              help in automating risk or marketing data models.
            </h5>
            <h5 class="font-reg">
              <i class="fas fa-dot-circle"></i> Python and java development
              skills can be used for process automation or building bots for
              various purposes.
            </h5>
          </div>

          <div class="data mt-5">
            <img src={data} alt="" class="img-fluid rounded-start" />
          </div>
        </div>
      </div>
      <div className="container">
        <div class="row justify-content-center">
          <div class="cardD m-2" style={{ width: '27rem' }}>
            <img
              src={riskanalysis}
              class="card-img-top"
              height="160px"
              alt="..."
            />
            <div class="card-body">
              <h5 class="card-title font-med">Risk Analytics</h5>
              <h6 class="card-text font-reg ">- Application Score Card​</h6>
              <h6 class="card-text font-reg ">- Collection Score Card​​</h6>
              <h6 class="card-text font-reg ">- Behaviour Score Cards​</h6>
              <h6 class="card-text font-reg ">
                - Differential Pricing basis Risk​​
              </h6>
              <h6 class="card-text font-reg ">- Risk Categorisation​​</h6>
            </div>
          </div>
          <div class="cardD m-2" style={{ width: '27rem' }}>
            <img src={campaign} class="card-img-top" height="160px" alt="..." />
            <div class="card-body">
              <h5 class="card-title font-med">Campaign Management</h5>
              <h6 class="card-text font-reg ">- Marketing Campaign​</h6>
              <h6 class="card-text font-reg ">- Retention Campaign​​</h6>
              <h6 class="card-text font-reg ">- Activation Campaign​​</h6>
            </div>
          </div>
          <div class="cardD m-2" style={{ width: '27rem' }}>
            <img
              src={descriptive}
              class="card-img-top"
              height="160px"
              alt="..."
            />
            <div class="card-body">
              <h5 class="card-title font-med">
                Descriptive and Predictive Analytics
              </h5>
              <h6 class="card-text font-reg ">- Portfolio Analysis​</h6>
              <h6 class="card-text font-reg ">
                - Customer Profitability Analysis​​​
              </h6>
              <h6 class="card-text font-reg ">- Vintage Report​</h6>
              <h6 class="card-text font-reg ">- Next Best Product​​</h6>
              <h6 class="card-text font-reg ">- Marketing score card​</h6>
              <h6 class="card-text font-reg ">- Customer Value Score​​</h6>
            </div>
          </div>
          <div class="cardD m-2" style={{ width: '27rem' }}>
            <img src={closeup} class="card-img-top" height="160px" alt="..." />
            <div class="card-body">
              <h5 class="card-title  font-med ">Image Processing</h5>
              <h6 class="card-text font-reg ">
                - OCR to capture details from Image​
              </h6>
              <h6 class="card-text font-reg ">- Masking section of Image​​</h6>
              <h6 class="card-text font-reg ">
                - Highlight / Underline specific sections / words from Image​
              </h6>
              <h6 class="card-text font-reg ">
                - OCR for multiple languages​​
              </h6>
            </div>
          </div>
          <div class="cardD m-2" style={{ width: '27rem' }}>
            <img src={Business} class="card-img-top" height="160px" alt="..." />
            <div class="card-body">
              <h5 class="card-title font-med">Business Intelligence (BI)</h5>
              <h6 class="card-text font-reg">- Report​</h6>
              <h6 class="card-text font-reg">- Dashboard​​</h6>
              <h6 class="card-text font-reg">- MIS​</h6>
              <h6 class="card-text font-reg">- Sentiment Analysis​​</h6>
            </div>
          </div>
          <div class="cardD m-2" style={{ width: '27rem' }}>
            <img src={resAug} class="card-img-top" height="160px" alt="..." />
            <div class="card-body">
              <h5 class="card-title font-med">Resource Augmentation</h5>
              <h6 class="card-text font-reg ">
                - Data Analytics as a service​
              </h6>
              <h6 class="card-text font-reg ">- Analytics ODC​</h6>
              <h6 class="card-text font-reg ">- Skilled resources​</h6>
              <h6 class="card-text font-reg ">
                - SAS, Python, Tableau, PowerBI, ETL tools, SQL​
              </h6>
              <h6 class="card-text font-reg ">- Data Mart/ Data Warehouse​</h6>
            </div>
          </div>
        </div>
      </div>
      <div className="container">
        <div class="row justify-content-center mt-5">
          <div className="text-center"><h4>Risk Analytics</h4></div>
          <div
                class="cardD text-dark mb-3 m-3"
                style={{"max-width": "35rem"}}
              >
                <div class="card-header font-med"><h5>Application Scorecards</h5></div>
                <div class="card-body">
                  <h6 class="card-text font-reg">
                    - Product/scheme level scorecards to predict loan applicant’s
                    propensity to default on loan.
                  </h6>
                  <h6 class="card-title">Benifits :</h6>
                  <h6 class="font-reg">- Uniform decisioning</h6>
                  <h6 class="font-reg">- Faster decisioning TAT</h6>
                  <h6 class="font-reg">- Risk based pricing</h6>
                </div>
          </div>
          <div
                class="cardD text-dark  mb-3 m-3"
                style={{"max-width": "35rem"}}
              >
            <div class="card-header font-med"><h5>Collection scorecards</h5></div>
                <div class="card-body">
                  <h6 class="card-text font-reg">- Soft Collections scorecards</h6>
                  <h6 class="card-text font-reg">- Recovery models</h6>
                  <h6 class="card-title font-med">Benifits :</h6>
                  <h6  class="font-reg">- Better collection productivity</h6>
                  <h6  class="font-reg">- Optimize account allocation</h6>
                </div>
          </div>
          <div
                class="cardD text-dark mb-3 m-3"
                style={{"max-width": "35rem"}}
              >
                <div class="card-header font-med"><h5>Behavioral scorecards</h5></div>
                <div class="card-body">
                  <h6 class="card-text font-reg">
                    - Scorecards that study past behaviour on loans to estimate
                    the behaviour on new offering
                  </h6>
                  <h6 class="card-title font-med">Benifits :</h6>
                  <h6 class='font-reg'>- Very useful in offering top-up and refinance facilities</h6>
                </div>
          </div>
        </div>
      </div>
      <div className="container">
      <div class="col mt-3">
        <h4>We expertise in various analytics tools like:</h4>
        <div className='align-items-center'>
            <div class="d-flex flex-column col-lg-12">
            <div class="d-flex flex-row justify-content-center">
              <img
                src={java}
                class="digital img-fluid m-1"
                alt="..."
              />
              <img
                src={dataware}
                class="digital img-fluid m-1"
                alt="..."
              />
              <img
                src={power}
                class="digital img-fluid m-1"
                alt="..."
              />
              <img
                src={python}
                class="digital img-fluid m-1"
                alt="..."
              />
            </div>
            <div class="d-flex flex-row justify-content-center mt-3">
              <img
                src={R}
                class="digital img-fluid m-1"
                alt="..."
              />
              <img
                src={SAS}
                class=" digital img-fluid m-1"
                alt="..."
              />
              <img
                src={Tab}
                class=" digital img-fluid m-1"
                alt="..."
              />
            </div>
        </div> 
        </div>
       
     </div>
      </div>
      <Footer />
    </>
  )
}

export default DataAnalytics
