import React from 'react'
import Breadcrupms from '../Breadcrupms/Breadcrupms'
import Footer from '../Footer/Footer'
import Navbar2 from '../Navbar/Navbar2'
// import journey from '../../Assests/Images/about/journey.png'
import journey from '../../Assests/Images/about/journeyAbout.png'
import overview from '../../Assests/Images/about/Digital.webp'
import about3 from '../../Assests/Images/about/about3.png'


function AboutUs() {
  return (
    <>
      <Navbar2/>
      <Breadcrupms title='About Us'/>
      <div className="container">
        <div className="journey p-5">
            <h3 className='font-med'>Our Journey</h3>
            <div className="d-lg-flex justify-content-between">
            <div className='col-lg-6'>
                <h5 className='font-reg mt-4'>Quantafic Business Solutions started its journey in 2014, when the founders saw the market’s need for smarter analytical and software products, and better end-to-end services in the banking space. Our focus has been to develop smart software and analytics-backed solutions that meet the growing demands of banks and NBFCs.</h5>
                <h5 className='font-reg mt-4'>With disruptive innovations in technologies banks and NBFC need to adopt to new technologies like RPA, OCR, Machine Learning models, Digital Applications, Mobile first and many more to ensure the millennials customers are added while old customers are effectively served.</h5>
            </div>
                <div className="col-lg-6 p-md-3">
                    <img src={journey} class="img-fluid m-auto" alt="..."/>
                </div>

            </div>
            <h5 className='font-reg mt-4'> Quantafic identified this need and launched digital products with new innovative technologies which help its customer optimise the total cost of ownership along with high scalability.</h5>
        </div>
        <div className="overview p-5">
         <h3 className='font-med'>Our Overview</h3>
          <div className="d-md-flex">
          <div className="col-md-5 col-9 p-3  ">
             <img src={overview} class="img-fluid m-auto" alt="..."/>
          </div>
          <div className='col-md-7 order-2  '>
            <h5 className='font-reg mt-4'>Quantafic is ISO-27001 certified for its quality of delivery.</h5>
            <h5 className='font-reg mt-4'>Many financial Institutes selected Quantafic in their digitalisation journey because of Quantafic’ s rich domain expertise and skills to build scalable and secured digital applications.</h5>
            <h5 className='font-reg mt-4'>Quantafic achieves digital transformation by offerings readily available products or pluggable digital assets which can be stitched together for quick digital transformation.</h5>
            <h5 className='font-reg mt-4'>Quantafic products and digital assets are developed with technology which offers very low total cost of ownership (TCO) and are designed to handle very high volume with highest security standards.</h5>
          </div>
          </div>
        </div>
        <div className="expertise p-5">
         <h3 className='font-med'>Our Expertise</h3>
        <div className="d-md-flex">
          <div className='col-md-7'>
            <h5 className='font-reg mt-4'>Within span of 8 years of existence Quantafic has successfully delivered more than 30 digitalisation projects and 8+ Mobile applications.</h5>
            <h5 className='font-reg mt-4'> Quantafic digital embark on new age technologies like, Image processing, Machine learning algorithms, Java/J2EE, Python, Robotic process automation, Android and iOS mobile development while building products and help its client in digital transformation.</h5>
          </div>
          <div className="col-md-5 col-9 p-3  ">
             <img src={about3} class="img-fluid m-auto" alt="..."/>
          </div>
        </div>
        </div>

      </div>
      <Footer/>
    </>
  )
}

export default AboutUs
