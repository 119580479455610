import React from 'react'
import Navbar2 from './Navbar/Navbar2'
import Hero from './Hero/Hero_section'
import Offerings from './Offerings/Offerings'
import CaseStudies from './CaseStudies/CaseStudies'
import Footer from './Footer/Footer.js'
import Testimonials from './Testimonials/Testimonials'
import QverifyHeroSection from './Hero/QverifyHero/QverifyHeroSection'
import Features from './QFeatures/Features'
import Department from './Department/Department'
import ProcessFlow from './ProcessFlow/ProcessFlow'
import ScheduleButton from './ScheduleButton/ScheduleButton'
import ScheduleModal from './ScheduleButton/ScheduleModal'

export default function Home() {
  return (
    <>
        
        <Navbar2/>
        <QverifyHeroSection/>
        {/* <Hero/> */}
        <Features/>
        <Department/>
        <ProcessFlow/>
        {/* <Offerings/> */}
        <CaseStudies/>
        <Testimonials/>
        <ScheduleModal/>
        <Footer/>
    </>
  )
}
