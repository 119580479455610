import React from 'react'
import Breadcrupms from '../../Breadcrupms/Breadcrupms'
import Footer from '../../Footer/Footer'
import Navbar2 from '../../Navbar/Navbar2'
import bureau from '../../../Assests/Images/bureau.jpg'
import bureau1 from '../../../Assests/Images/products/bureau1.png'
import bureau2 from '../../../Assests/Images/case-studies/bureau.png'
import riskevalutor from '../../../Assests/Images/case-studies/riskevalutor.png'

function BureauCon() {
  return (
    <>
      <Navbar2 />
      <Breadcrupms title="Q-Connect" />
      <div className="container">
        <div class="card row align-items-center p-5 border-0">
          <div className="h-25 d-lg-flex justify-content-center" >
            <div className="col-lg-3 col-md-5 col-sm-5 me-4 ms-3">
              <img src={bureau} class="img-fluid m-auto w-100" alt="" />
            </div>
            <div className="mt-2 pt-lg-5 col-lg-7">
            <h5 className="font-reg">
                <b>
                Introducing the ultimate solution for seamless bureau
                  integration and connectivity!
                </b>
              </h5>
              <h5 className="text-justify font-reg">
                Our banking product offers a cutting-edge connector that
                streamlines the integration process with credit bureaus,
                allowing you to access and exchange data with ease. With this
                powerful tool, you can take your business to the next level by
                enhancing your credit decisioning capabilities and streamlining
                your operations.
              </h5>
            </div>
          </div>
          <div className="h-25 d-lg-flex justify-content-center">
            <div className="col-lg-7 me-xl-5 ps-xl-4 ms-xl-5  ms-xl-1 ms-lg-4 ps-lg-5">
              <h5 className="mt-3 font-reg">
                At the heart of our product is the bureau connector, which
                enables fast, secure, and reliable data exchange between your
                systems and credit bureaus. Whether you need to retrieve credit
                reports, update customer information, or exchange other data,
                our connector makes it easy and efficient. And with our robust
                security features, you can rest assured that your data is always
                protected.
              </h5>
            </div>
            <div className="col-lg-3 col-md-5 col-sm-5 ms-lg-4 ms-xl-4 ms-5  ">
              <img src={bureau1} class="img-fluid w-100" alt="" />
            </div>
          </div>
          <div className="h-25 d-lg-flex justify-content-center">
            <div className="col-lg-10 me-xl-5 ps-xl-4 ms-xl-4 me-xl-5 ps-lg-5 mt-2">
              <h5 className=" font-reg">
                Our bureau integration solution is designed with flexibility in
                mind. Whether you need to integrate with a single bureau or
                multiple bureaus, our solution can be easily customized to meet
                your specific requirements. And with its intuitive user
                interface, you can manage your integration processes with ease,
                even if you don't have extensive technical expertise.
              </h5>
            </div>
          </div>
          <div className="d-lg-flex justify-content-center ">
            <div className="col-lg-10 me-xl-5 ps-xl-4 ms-xl-4 me-xl-5 ps-lg-5 mt-2">
              <h5 className="mt-1 font-reg">
                <b> So why wait? </b> <br /> Start streamlining your credit
                decisioning process and maximizing your productivity today with
                our cutting-edge banking product. Whether you're a lender,
                financial institution, or other organization, our bureau
                integration and connector solution is the perfect solution for
                your business.
              </h5>
            </div>
          </div>
          <div className="mt-5">
            <hr />
            <h2 className='font-semi text-center mt-5 mb-3 pb-5'>Case Study</h2>
            <img src={bureau2} class="img-fluid w-100  mb-5" alt="" />
            <img src={riskevalutor} class="img-fluid w-100" alt="" />
          </div>
        </div>
      </div>
      <Footer />
    </>
  )
}

export default BureauCon
