import React, { useEffect, useState } from "react";
import Navbar2 from "../Navbar/Navbar2";
import "../Form/apply.css";
import Footer from "../Footer/Footer";
import { useForm } from "react-hook-form";
import axios from "axios";
import { useLocation, useParams } from "react-router-dom";

export default function Apply(props) {
  // const {
  //   id ,
  //   position,
  //   location
  // } = props

  const { state } = useLocation();

  // useEffect(() => {
  //   // console.log("Apply ====> ", state);

  // }, []);

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [number, setNumber] = useState("");
  const [designation, setDesignation] = useState("");
  const [company, setCompany] = useState("");
  const [notice, setNotice] = useState("");
  const [file, setFile] = useState("");
  const [base64, setBase64] = useState("");
  const [reffName, setReffName] = useState("");
  const [select, setSelect] = useState("");
  const [disabled, setDisabled] = useState(true);

  // const {id}
  // console.log(reff);
  const handleApplication = (data) => {
    console.log(data);
    console.log(data.file[0]);
    //console.log(this.getBase64(data.file[0]))

    let reader = new FileReader();
    //  this.base64="";
    reader.readAsDataURL(data.file[0]);
    reader.onload = function () {
      //  console.log(reader.result);
      setBase64(reader.result);

      try {
        axios
          .post("https://leaves.quantafic.in/pro/api/qbs/career", {
            body: {
              fullName: name,
              emailId: email,
              mobNo: number,
              currDesg: designation,
              // company : company,
              currNoticePrd: notice,
              resume: reader.result,
              jobId: state.params.params.jobId ? state.params.params.jobId : 0,
              position: state.params.params.position,
              loc: state.params.params.location,
            },
          })
          .then((response) => {
            if (response.status === 200) {
              setName("");
              setEmail("");
              setNumber("");
              setDesignation("");
              setCompany("");
              setNotice("");
              setFile("");
              alert("Data submitted successfully");
            } else {
              alert("Some error occured");
            }
            console.log(response.data);
          });
      } catch (err) {
        console.log(err);
      }
    };
    reader.onerror = function (error) {
      console.log("Error: ", error);
    };
    console.log("base64=" + base64);
  };

  // useEffect(() => {
  //   axios.post("https://jsonplaceholder.typicode.com/users", {
  //     body : JSON.stringify({
  //       name : name,
  //       email : email,
  //     })
  //   })
  //     .then((response) => {
  //       handleApplication(response.data);
  //     })

  // }, []);
  const handleChange = (e) => {
    let val = e.target.value;
    console.log(val);

    setSelect(e.target.value);
    if (val === "Referred by Friend") {
      setDisabled(false);
    } else {
      setDisabled(true);
    }
    console.log("cja", disabled);
  };

  const handleError = (errors) => {};

  const applicationOptions = {
    name: { required: "Name is required" },
    email: {
      required: "Email is required",
      pattern: {
        // value: /^\S+@\S+$/i,
        value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
        message: "This is not valid email",
      },
    },
    number: {
      required: "Number is required",
      pattern: {
        value: /^([+]?[\s0-9]+)?(\d{3}|[(]?[0-9]+[)])?([-]?[\s]?[0-9])+$/i,
        message: "This is not a valid Number",
      },
      minLength: {
        value: 10,
        message: "Number must contain 10 digits",
      },
      maxLength: {
        value: 10,
        message: "Number can't contain more than 10 digits",
      },
    },
    designation: { required: "Designation is required" },
    company: { required: "Company is required" },
    notice: {
      required: "Notice period is required",
      maxLength: {
        value: 2,
        message: "Notice can't contain more than 2 digits",
      },
    },
    file: { required: "File is required" },
    reffName: { required: "Please enter the persons name" },
    select: { required: "Please select one" },
  };
  return (
    <>
      <Navbar2 />
      <div className="container mt-5">
        <div className="careercard m-auto p-5 shadow-lg ">
          <form onSubmit={handleSubmit(handleApplication, handleError)}>
            <div className="form">
              <h4 className="mb-5 text-center">Application Form</h4>
              <div className="d-md-flex justify-content-center">
                <div className="field_auto flex-column row">
                  <label className="font-reg p-0">Full Name</label>
                  <input
                    className="form-control font-reg"
                    type="tel"
                    name="name"
                    value={name}
                    placeholder="Full Name"
                    {...register("name", applicationOptions.name)}
                    onChange={(e) => setName(e.target.value)}
                  ></input>
                  <small className="text-danger">
                    {errors?.name && errors.name.message}
                  </small>
                </div>
                <div className="field_auto flex-column row">
                  <label className="font-reg p-0">Email Address</label>
                  <input
                    className="form-control font-reg"
                    type="email"
                    name="email"
                    value={email}
                    placeholder="Email-Id"
                    {...register("email", applicationOptions.email)}
                    onChange={(e) => setEmail(e.target.value)}
                  ></input>
                  <small className="text-danger">
                    {errors?.email && errors.email.message}
                  </small>
                </div>
              </div>
              <div className="d-md-flex justify-content-center mt-4">
                <div className="field_auto flex-column row">
                  <label className="font-reg p-0">Mobile Number</label>
                  <input
                    className="form-control font-reg"
                    type="text"
                    name="number"
                    value={number}
                    placeholder="Mobile Number"
                    {...register("number", applicationOptions.number)}
                    onChange={(e) => setNumber(e.target.value)}
                  ></input>
                  <small className="text-danger">
                    {errors?.number && errors.number.message}
                  </small>
                </div>
                <div className="field_auto flex-column row">
                  <label className="font-reg p-0">Current Designation</label>
                  <input
                    className="form-control font-reg"
                    type="text"
                    name="designation"
                    value={designation}
                    placeholder="Designation"
                    {...register("designation", applicationOptions.designation)}
                    onChange={(e) => setDesignation(e.target.value)}
                  ></input>
                  <small className="text-danger">
                    {errors?.designation && errors.designation.message}
                  </small>
                </div>
              </div>
              <div className="d-md-flex justify-content-center mt-4">
                <div className="field_auto flex-column row">
                  <label className="font-reg p-0">Notice Period</label>
                  <input
                    className="form-control font-reg"
                    type="number"
                    name="notice"
                    value={notice}
                    placeholder="Duration of Notice Period"
                    {...register("notice", applicationOptions.notice)}
                    onChange={(e) => setNotice(e.target.value)}
                  ></input>
                  <small className="text-danger">
                    {errors?.notice && errors.notice.message}
                  </small>
                </div>
                <div className="field_auto flex-column row">
                  <label className="font-reg p-0">Resume</label>
                  <input
                    className="form-control font-reg text-center"
                    type="file"
                    name="file"
                    value={file}
                    placeholder="Upload a File"
                    {...register("file", {
                      validate: {
                        lessThan10MB: (files) =>
                          files[0]?.size < 5000000 || "Max 5MB",
                        acceptedFormats: (files) =>
                          [
                            "application/pdf",
                            "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
                            "application/msword",
                          ].includes(files[0]?.type) ||
                          "Only allowed PDF, DOCX & DOC file type",
                      },
                    })}
                    onChange={(e) => setFile(e.target.value)}
                  ></input>
                  <small className="text-danger">
                    {errors?.file && errors.file.message}
                  </small>
                </div>
              </div>
              <div className="d-md-flex justify-content-center mt-4">
                <div className="field_auto flex-column row">
                  <label className="font-reg p-0">
                    How did you got to know about job posting
                  </label>
                  <select
                    className="form-select form-select-sm "
                    aria-label="Default select example"
                    {...register(
                      "select",
                      applicationOptions.select
                      )}                
                      onChange={(e) => handleChange(e)}
                  >
                    <option value={select}>Select</option>
                    <option value="Linkedin">Linkedin</option>
                    <option value="Referred by Friend">Referred by Friend</option>
                    <option value="Quantafic Website">Quantafic Website</option>
                  </select>
                  <small className="text-danger">
                    {errors?.select && errors.select.message}
                  </small>
                </div>
                <div className="field_auto flex-column row">
                  <label className="font-reg p-0">Reffered By</label>
                  <input
                    className="form-control font-reg"
                    type="tel"
                    name="RefferedBy"
                    disabled={disabled}
                    value={reffName}
                    placeholder="Reffered By"
                    {...register("reffName", applicationOptions.reffName)}
                    onChange={(e) => setReffName(e.target.value)}
                  ></input>
                  {disabled === false && (
                    <small className="text-danger">
                      {errors?.reffName && errors.reffName.message}
                    </small>
                  )}
                </div>
              </div>

              <div className="apply-btn m-auto p-5 text-center">
                <button className="btn shadow m-auto" type="submit">
                  <h6>Submit</h6>
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
      <Footer />
    </>
  );
}
