import React from 'react'
import Navbar2 from '../../Navbar/Navbar2'
import Breadcrumps from '../../Breadcrupms/Breadcrupms'
import apicorn from '../../../Assests/Images/apicorn/API HUB.png'
import Footer from '../../Footer/Footer'

function ApiCorn() {
    return (
        <>
            <Navbar2 />
            <Breadcrumps title ='APIcorn' />
            <div className="container p-5">
                <div class="card align-items-center border-0 p-5">
                    <h5 class='font-reg'>APIcorn is Quantafic's API Hub, helps to streamline interaction of your software components.ous secured options of API connections available with APIcorn and thereby providing faster response to business needs.</h5>
                    <h5 class='font-reg'>APIcorn, an API hub product of Quantafic allows to connect to multiple external application with a ease. Product support various type of API like REST, SOAP, Deb Link, Microservices.</h5>
                    <h5 class='font-reg'>In one of the implementations Quantafic API hub allows lender to connect with 26 external applications and data exchange is handled with ease and secured manner. Encrypted data storage allows saving data security.
                    </h5>
                    <div class="col">
                        <img src={apicorn} class="img-fluid mt-5 mb-5" alt="" />
                    </div>
                </div>
            </div>
            <Footer/>
        </>
    )
}

export default ApiCorn
