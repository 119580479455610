import React from 'react'
import Breadcrupms from '../../Breadcrupms/Breadcrupms'
import Footer from '../../Footer/Footer'
import Navbar2 from '../../Navbar/Navbar2'
import benefits from '../../../Assests/Images/benefits.jpg'
import search from '../../../Assests/Images/search.jpg'
import dashboard from '../../../Assests/Images/dashboard.jpg'
import protection_information from '../../../Assests/Images/protection_information.jpg'

function DocMan() {
  return (
    <>
      <Navbar2/>
      <Breadcrupms title='Document Management'/>
       <div className="container">
        <div class="doc-head">
            <h3 class="pt-4 mb-4 font-med">What is Document Management System?</h3>
        </div>
        <div class="doc-cont">
            <h5 class="px-5 font-reg">A document management system (DMS) is usually a computerized system used to store, share, track and manage files or documents. Some systems include history tracking where a log of the various versions created and modified by different users is recorded. The term has some overlap with the concepts of content management systems. It is often viewed as a component of enterprise content management (ECM) systems and related to digital asset management, document imaging, workflow systems and records management systems.</h5>
        </div>
        <div class="doc-head">
            <h5 class="pt-4 mb-4 px-5 font-reg">Quantafic’s Document Management system is built on open-source technology – Alfresco</h5>
         </div>
         <div class="doc-features">
            <h4 class="pt-4 mb-4 font-reg">Features</h4>
            <hr class="w-25"/>
            <div class="d-lg-flex justify-content-between">
                <div class="col">
                    <h5 class="font-med"> - Security</h5>
                    <h5 class="font-reg ps-3">Authentication</h5>
                    <h5 class="font-reg ps-3">Single sign-on</h5>
                    <h5 class="font-reg ps-3">Authorization (Role Based Document access control)</h5>
                    <h5 class="font-reg ps-3">Role based view or download document</h5>
                    <h5 class="font-reg mt-4 Metro-Regular"> - Searching document or searching based on content relevance</h5>
                    <h5 class="font-reg ps-3">Filtering search results</h5>
                    <h5 class="font-reg ps-3">Searching keywords in PDF or office documents like word, excel, PPT</h5>
                    <h5 class="font-reg mt-4 Metro-Regular"> - Improved user access to content Increases enterprise effectiveness</h5>
                    <h5 class="font-reg ps-3">Easier for users to get access to the right information at the right time from their application</h5>
                </div>
                <div class="feature_img col-6 ">
                    <img class="img-fluid" src={benefits} style={{ width: "500px" }}/>
                </div>
            </div>
            <div>
                <h5 className='font-reg'> - Defines a set of standards, technology and interface, that works across multiple vendor and technology providers.</h5>
                <h5 className='font-reg'> - Tagging the documents.</h5>
                <h5 className='font-reg'> - Adding comments, feedback, review against document.</h5>
                <h5 className='font-reg'> - Maintain version history of documents.</h5>
                <h5 className='font-reg' > - Multiple content access methods: Web client, FTP, WebDAV, CIFS (mapped network drive).</h5>
                <h5 className='font-reg' > - Each team or business unit systematically manages content within its own environment.</h5>
                <h5 className='font-reg'> - Knowledge workers can use a single application to interact with content stored and managed by ECM systems.</h5>
            </div>
        </div>
        <div class="doc-search">
            <h4 class="pt-4 mb-4 mt-4 font-med">Document Search</h4>
            <hr class="w-25"/>
            <div class="d-lg-flex justify-content-between">
                <div class="col">
                    <h5 class="font-reg"> - Basic Search</h5>
                    <h5 class="font-reg">Search any document by keywords. The application displays 5 most relevant results while entering the keywords.</h5>
                    <h5 class="font-reg">A list of relevant documents is displayed once the required keywords are entered and searched.</h5>
                    <h5 class="font-reg">Supports Wildcard search (by using terms, phrases, and exact phrases using "*" and "?“)</h5>
                    <h5 class="font-reg">E.g.: The keywords such as ‘warshi?’, ‘*ship’ and ‘*sh??’ all matches "warship".· </h5>
                    <h5 class="mt-4 font-reg"> - Advance Search</h5>
                    <h5 class="font-reg">Parameters such as Dept, Product, Geography, Doc Type, Mime type, Range of Created date, etc</h5>
                </div>
                <div class="feature_img col-6 ">
                    <img class="img-fluid" src={search} style={{ width: "500px" }}/>
                </div>
            </div>
            <div>
                <h5 class="font-reg"> - Search Results Filter</h5>
                <h5 className='font-reg'>Filter on document properties (dept, prod, doc-type, sector, date, size, etc.) once the Search results is displayed</h5>
                <h5 className='font-reg'>Filters shows count of documents tagged to each filter option and allows you to progressively narrow down the search results.</h5>
            </div>
        </div>
        <div class="doc-search">
            <h4 class="pt-4 mb-4 mt-4 font-med">Dashboard and Permissions</h4>
            <hr class="w-25"/>
            <div class="d-lg-flex justify-content-between">
                <div class="col">
                    <h5 class="font-reg"> - Dashboard Customization</h5>
                    <h5 class="font-reg" >Dashboard uses Dashlets (windows which displays data) for user interface to present information.</h5>
                    <h5 class="font-reg" >The number of Dashlets depends on the features which you find useful and wish to use..</h5>
                    <h5 class="font-reg" >Platform features various Dashlets, each with a different function</h5>
                    <h5 class="font-reg" >These Dashlets are placed in 2 column format and platform allows you to reposition the Dashlets as well as assign keyboard shortcut to each of them. </h5>
                    <h5 class="font-reg" >Various Dashlets such as Recent feeds, My Favorites, Saved Search, etc. are available which provides a list of corresponding documents, hence enabling a quick access to useful documents. </h5>
                </div>
                <div class="feature_img col-md-6 ">
                    <img class="img-fluid" src={dashboard} style={{ width: "500px" }}/>
                </div>
            </div>
            <div class="d-lg-flex justify-content-between">
                <div class="col-md-6 order-md-1 order-2 feature_img  ">
                    <img class="img-fluid" src={protection_information} style={{ width: "500px" }}/>
                </div>
                <div class="order-md-2 order-1 mt-5 p-5  ">
                    <h5 class="font-reg  "> - Accessing Restricted Documents</h5>
                    <h5 class="font-reg" >Documents owner can crate link and share the link with respective user (user should be part of platform)</h5>
                    <h5 class="font-reg" >User can only view, review, or give feedback against document</h5>
                </div>
            </div>
           </div>
       </div>
      <Footer/>
    </>
  )
}

export default DocMan
