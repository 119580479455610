import React from 'react'
import first from '../../Assests/Images/qverifi/1st.png' 
import second from '../../Assests/Images/qverifi/2nd.png' 
import third from '../../Assests/Images/qverifi/3rd.png' 
import fourth from '../../Assests/Images/qverifi/4th.png' 
import fifth from '../../Assests/Images/qverifi/5th.png' 
import '../ProcessFlow/process.css'

const ProcessFlow = () => {
  return (
    <>
    <div className='process-bg mt-5'>
    <div className="section-header font-semi mt-5">
        <h2>Process Flow</h2>
      </div>
      <div className="container p-5">
       {/* <h3 className='font-semi text-center mb-5'>Process Flow</h3> */}
       <div className='col-md-10 m-auto'>
         <div className="d-md-flex justify-content-start mt-4">
            <div className="col-md-8 d-md-flex ">
            <div className='img m-auto'>
                <img src={first} className='img-fluid' alt="" />
                </div>
                <div className="col-md-6 content my-auto">
                    <h5 className='font-semi text-center'>LOS Integration / Bulk Upload​</h5>
                    <h6 className='font-reg text-center'>Customer Details are fetched by integration with LOS or Manual Bulk Upload​​</h6>
                </div>
            </div>
         </div>
         <div className="d-md-flex justify-content-end mt-4">
            <div className="col-md-8 d-md-flex">
                <div className='img order-md-2 m-auto'>
                <img src={second} className='img-fluid' alt="" />
                </div>
                <div className="col-md-6  order-md-1 content my-auto">
                    <h5 className='font-semi text-center'>QVerifi allocation Logic ​​</h5>
                    <h6 className='font-reg text-center'>Allocation logic configured in QVerifi decides the Agency to whom the case to be allocated​​​</h6>
                </div>
            </div>
         </div>
         <div className="d-md-flex justify-content-start mt-4">
            <div className="col-md-8 d-md-flex ">
            <div className='img m-auto'>
                <img src={third} className='img-fluid' alt="" />
                </div>
                <div className="col-md-6 content my-auto">
                    <h5 className='font-semi text-center'>Agency Module​</h5>
                    <h6 className='font-reg text-center'>Agency Manager able to view cases allocated to it​​​</h6>
                </div>
            </div>
         </div>
         <div className="d-md-flex justify-content-end  mt-4">
            <div className="col-md-8 d-md-flex">
                <div className='img order-md-2 m-auto'>
                <img src={fourth} className='img-fluid' alt="" />
                </div>
                <div className="col-md-6  order-md-1 content my-auto">
                    <h5 className='font-semi text-center'>Verifier Mobile Application​​​</h5>
                    <h6 className='font-reg text-center'>Verifier get case allocated in mobile app​​​​</h6>
                </div>
            </div>
         </div>
         <div className="d-md-flex justify-content-start mt-4">
            <div className="col-md-8 d-md-flex ">
            <div className='img m-auto'>
                <img src={fifth} className='img-fluid' alt="" />
                </div>
                <div className="col-md-6 content my-auto">
                    <h5 className='font-semi text-center'>Credit Manager Module​​</h5>
                    <h6 className='font-reg text-center'> Credit Manager can approve report or Reassign to same or different agency for reverification​.Credit Manager gets the 360 view of agency work​​</h6>
                </div>
            </div>
         </div>
       </div>
      </div>     
    </div>
    </>
  )
}

export default ProcessFlow
