import React from 'react'
import "../Products/products.css"
import products from '../../Assests/Images/case_imgs/products.webp'
import Breadcrupms from '../Breadcrupms/Breadcrupms'
import Navbar2 from '../Navbar/Navbar2'
import Footer from '../Footer/Footer'
import { Link } from 'react-router-dom'
import qcam from '../../Assests/Images/products/QCAM 1.webp'
import qverify from '../../Assests/Images/products/verifyyy_1.webp'
import qmandate from '../../Assests/Images/products/mandate1_1.webp'
import qrulez from '../../Assests/Images/products/background 1.png'
import multi from '../../Assests/Images/products/multibureau_1.webp'
import scan from '../../Assests/Images/products/close-up-phone-scanning-parcel-informations.jpg'
// import XPenzImg from '../../Assests/Images/products/XPenzImg.png'

export default function Products() {
  return (
    <>
     
     <Navbar2/>
     <Breadcrupms title = "Products"/>
      <div className="container">
           <div class="row justify-content-center">
              <div class="row justify-content-center">
                  <div class="col-lg-4 col-sm-6 mb-4 ">
                      <div class="card h-100 rounded-5 align-items-center border-0 shadow m-2 ">
                        <div className="pro_img">
                            <img src={qverify} alt="" />
                        </div>
                        <Link to='/qverify'><div className="pro_cont px-4 py-4 text-center">
                            <h5 className='white font-med'>Q-Verifi</h5>
                            <h6 className='white font-reg'>Q-Verifi is used for digitally transforming multiple processes.</h6>
                            </div> </Link>                                                 
                        </div>
                  </div>
                  <div class="col-lg-4 col-sm-6 mb-4 ">
                      <div class="card h-100 rounded-5 align-items-center border-0 shadow m-2 ">
                        <div className="pro_img">
                            <img src={qmandate} alt="" />
                        </div>
                        <Link to='/mandate'><div className="pro_cont px-4 py-4 text-center">
                            <h5 className='white font-med'>Q-Mandate</h5>
                            <h6 className='white font-reg'>Q-Mandate allows to configure various message transfer mechanism</h6>
                            </div></Link>                                                  
                        </div>
                  </div>
                  <div class="col-lg-4 col-sm-6 mb-4 ">
                      <div class="card h-100 rounded-5 align-items-center border-0 shadow m-2 ">
                        <div className="pro_img">
                            <img src={qrulez} alt="" />
                        </div>
                        <Link to='/qrulz'><div className="pro_cont px-4 py-4 text-center">
                            <h5 className='white font-med'>Q-Rulez</h5>
                            <h6 className='white font-reg'>Easily configure your business rules using Q-Rulez </h6>
                            </div> </Link>                                                 
                        </div>
                  </div>
                  <div class="col-lg-4 col-sm-6 mb-4 ">
                      <div class="card h-100 rounded-5 align-items-center border-0 shadow m-2 ">
                        <div className="pro_img">
                            <img src={qcam} alt="" />
                        </div>
                        <Link to='/qcam'><div className="pro_cont px-4 py-4 text-center">
                            <h5 className='white font-med'>Q-CAM</h5>
                            <h6 className='white font-reg'>Q-CAM is Quantafic Digital Customer onboarding product.</h6>
                        </div></Link>                                                  
                        </div>
                  </div>
                  <div class="col-lg-4 col-sm-6 mb-4 ">
                      <div class="card h-100 rounded-5 align-items-center border-0 shadow m-2 ">
                        <div className="pro_img">
                            <img src={multi} alt="" />
                        </div>
                        <Link to='/bureauCon'><div className="pro_cont px-4 py-4 text-center">
                            <h5 className='white font-med'>Q-Connect</h5>
                            <h6 className='white font-reg'>Configurable connection to multiple Bureaus.</h6>
                            </div></Link>                                                
                        </div>
                  </div>
                  <div class="col-lg-4 col-sm-6 mb-4 ">
                      <div class="card h-100 rounded-5 align-items-center border-0 shadow m-2 ">
                        <div className="pro_img">
                            <img src={scan} alt="" />
                        </div>
                        <Link to='/OCR'><div className="pro_cont px-4 py-4 text-center">
                            <h5 className='white font-med'>Q-Scan.AI</h5>
                            <h6 className='white font-reg'>Q-Scan.AI extracts relevant information from customers documents in real time </h6>
                            </div></Link>                                                  
                        </div>
                  </div>
                  <div class="col-lg-4 col-sm-6 mb-4 ">
                      {/* <div class="card h-100 rounded-5 align-items-center border-0 shadow m-2 ">
                        <div className="pro_img">
                            <img src={XPenzImg} alt="" />
                        </div>
                        <Link to='/XPenz'><div className="pro_cont px-4 py-4 text-center">
                            <h5 className='white font-med'>Q-Claim</h5>
                            <h6 className='white font-reg'>Quantafic digital expense processing application.</h6>
                            </div></Link>                                                  
                        </div> */}
                  </div>
              </div>
         </div>
      </div>
      <Footer/>
    </>
  )
}
        {/* <div className="row product align-items-center">
            <div className="d-lg-flex justify-content-center">
              <div className="d-flex">
                <div className="pro_card align-items-center m-3">
                        <div className="pro_img">
                            <img src={products} alt="" />
                        </div>
                        <div className="pro_cont">
                            <h5 className='white'>hello</h5>
                        </div>
                </div> 
              </div>
            </div>
        </div> */}