import React from 'react'
import FI from '../../Assests/Images/qverifi/FI.png'
import PD from '../../Assests/Images/qverifi/PD.png'
import TD from '../../Assests/Images/qverifi/TD.png'
import Legal from '../../Assests/Images/qverifi/Legal.png'
import seeding from '../../Assests/Images/qverifi/seeding.png'
import sampling from '../../Assests/Images/qverifi/sampling.png'
import assest from '../../Assests/Images/qverifi/assest.png'
import credit from '../../Assests/Images/qverifi/credit.png'
import rcu from '../../Assests/Images/qverifi/rcu.png'
import verifyProcess from '../../Assests/Images/qverifi/verifyProcess.png'


import '../Department/depart.css'

const Department = () => {
  return (
    <>
        {/* <div className="section-header font-semi mt-5"> */}
      {/* </div> */}
      <div className="container mt-5 p-1">
        <h2 className='text-center m-5'>Q-Verifi for multiple processes</h2>
        <div className="outer">
          <div className="col-md-12">
               <img src={verifyProcess} className="img-fluid" alt="" />
            {/* <div className="credit text-center mt-5 m-4 p-4"> */}
              {/* <h5 className="font-med text-center mt-3">
                For Credit Department
              </h5>
              <img src={credit} className="mt-5 img-fluid" alt="" /> */}
              {/* <div className=" col-md-7 col-7 d-md-flex m-auto justify-content-center">
                <div className="col-md-9 p-lg-5">
                  <img src={FI} className="img-fluid" alt="" />
                  <h6 className="font-reg">FI (Field Investigation)</h6>
                </div>
                <div className="col-md-9 p-lg-5">
                  <img src={TD} className="img-fluid" alt="" />
                  <h6 className="font-reg">Technical Discussion(PD)</h6>
                </div>
              </div>
              <div className="col-md-7 col-7 d-md-flex m-auto justify-content-center">
                <div className="col-md-9  p-lg-5">
                  <img src={PD} className="img-fluid" alt="" />
                  <h6 className="font-reg">Personal Discussion(PD)</h6>
                </div>
                <div className="col-md-9  p-lg-5">
                  <img src={Legal} className="img-fluid" alt="" />
                  <h6 className="font-reg">Legal</h6>
                </div>
              </div> */}
            {/* </div> */}
            {/* <div className="col-md-3 col-6 qverii text-center bordercard mx-auto my-auto">
              <h2 className="font-bold text-center">Q-Verifi</h2>
              <h6 className="font-reg text-center mt-4">
                360 Degree Agency Management
              </h6>
            </div> */}
            {/* <div className="rcu text-center bordercard mt-5 m-4 p-4"> */}
              {/* <h5 className="font-med text-center mt-3">For RCU Department</h5>
              <img src={rcu} className="mt-5 img-fluid" alt="" /> */}

              {/* <div className=" col-md-7 col-7 d-md-flex m-auto justify-content-center">
                <div className="col-md-9 p-lg-5">
                  <img src={seeding} className="img-fluid" alt="" />
                  <h6 className="font-reg">Seeding</h6>
                </div>
                <div className="col-md-9 p-lg-5">
                  <img src={sampling} className="img-fluid" alt="" />
                  <h6 className="font-reg">Sampling</h6>
                </div>
              </div>
              <div className=" col-md-7 col-7 d-md-flex m-auto justify-content-center">
                <div className="col-md-9 p-lg-5">
                  <img src={assest} className="img-fluid" alt="" />
                  <h6 className="font-reg">Assest Cross-Check</h6>
                </div>
                <div className="col-md-9 p-lg-5">
                  <img src={FI} className="img-fluid" alt="" />
                  <h6 className="font-reg">FI Cross-Check</h6>
                </div>
              </div> */}
            {/* </div> */}
          </div>
        </div>
      </div>
    </>
  )
}

export default Department
