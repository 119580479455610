import React from 'react'
import mandate from '../../../Assests/Images/mandate.png'
import Breadcrupms from '../../Breadcrupms/Breadcrupms'
import Footer from '../../Footer/Footer'
import Navbar2 from '../../Navbar/Navbar2'

function QMandate() {
  return (
    <>
      <Navbar2/>
      <Breadcrupms title='Q-Mandate'/>
        <div className="container ">
        <div class="card align-items-center border-0 p-5">
                    <h5 className='font-reg'>If you are looking for product which allows to configure various payment message formats and message transfer mechanism, then QMand8 fits your requirement. Payment formats can be configured bank wise and linked to the method in which particular bank needs payment file to be transferred to them. QMand8 is empowered by recon engine which allows to reconcile the transactions processed using QMand8 application.</h5>
                    <div class="col-lg-10">
                        <img src={mandate} class="img-fluid mt-5 mb-5" alt=""/>
                    </div>
                    <h5 className='font-reg'>QMand8 not only automates the onward payment processing but the received response or incoming transaction is also handled and appropriate payment engine gets the data.Preauthorised debit formats can be handled by using QMand8 for processing.API of the product allows to connect with core application and upload payment files or process it by integration.
                    </h5>
                </div>
        </div>
      <Footer/>
    </>
  )
}

export default QMandate
