import React from 'react'
import Breadcrupms from '../../Breadcrupms/Breadcrupms'
import Footer from '../../Footer/Footer'
import Navbar2 from '../../Navbar/Navbar2'
import Picture1 from '../../../Assests/Images/Picture1.png'
import qrulz_ad from '../../../Assests/Images/qrulz_ad.webp'

function Qrulz() {
  return (
    <>
      <Navbar2/>
      <Breadcrupms title = "Q-Rulez"/>
        <div className="container">
        <div class="card p-5 align-items-center border-0" >
                <div class="row g-0 align-items-center">
                    <div class="col-md-6 mt-4">
                        <div class="card-body">
                            <h5 class="card-title font-reg">Business rule engine automates the expert knowledge for repetitive
                                use. Rule engine is the brain of software applications. It needs to provide quick and
                                accurate response similar to human brain for any query raised with it.</h5>
                            <h5 className='font-reg'>
                                QRulez, a rule engine product of Quantafic, designed for IN-Memory processing of rules.
                                IN-memory processing feature, along with pattern match algorithm allows QRulez to
                                execute huge volumes of rules within fraction of seconds.
                            </h5>
                        </div>
                    </div>
                    <div class="col-md-6">
                        <img src={Picture1} class="img-fluid rounded-start" alt="..."/>
                    </div>
                </div>
                <div class="row w-100 g-0 border rounded-2 justify-content-center mt-5">
                    <div class="data m-2">
                        <img src={qrulz_ad} class="img-fluid" alt="..."/>                
                    </div>
                    <div class="col-md-6 m-4 rounded-2" style={{"background-color":"#D3E8FD"}}>
                      <div class="card-body">
                        <h4 class="card-title font-med">
                            Advantages of Q-Rulez:
                        </h4>
                        <h6 className='font-reg'><i class="fas fa-dot-circle"></i> In-Memory processing helps in large volume of rules in fraction of seconds.</h6>
                        <h6 className='font-reg'><i class="fas fa-dot-circle"></i> Rules are easily maintainable.</h6>
                        <h6 className='font-reg'><i class="fas fa-dot-circle"></i> No limitation on number of rules and type of rules.</h6>
                        <h6 className='font-reg'><i class="fas fa-dot-circle"></i> Rules can be modified and deployed without bringing down the application.</h6>
                      </div>
                    </div>
                </div>
                <div class="container mt-5 text-center">
                    <h5>QRulez, support configuration of various types of rules.</h5>
                     <div class="row justify-content-center">
                        <div class="qrule_card col-md-4 m-4 border rounded-2 " style={{"background-color": "#D3E8FD"}}>
                            <div class="  card-body">
                                <h5 class="card-title text-center font-reg" style={{"color": "black"}}>
                                    Mandatory constraints
                                </h5>
                                <h6 class="card-title font-reg">
                                    Rule that rejects the attempted business transactions.
                                </h6>
                            </div>
                        </div>
                        <div class=" qrule_card col-md-4 m-4 border rounded-2 "  style={{"background-color": "#D3E8FD"}}>
                            <div class=" card-body">
                                <h5 class="card-title font-reg" style={{"color": "black"}}>
                                    Action-enablers
                                </h5>
                                <h6 class="card-title font-reg">
                                    Rule that tests conditions and upon finding them true, initiate
                                    another business event, message, or other activity
                                </h6>
                            </div>
                        </div>
                    </div>
                    <div class="row justify-content-center">
                        <div class=" qrule_card col-md-4 m-4 border rounded-2 "  style={{"background-color": "#D3E8FD"}}>
                            <div class="  card-body ">
                                <h5 class="card-title font-reg" style={{"color": "black"}}>
                                    Computations
                                </h5>
                                <h6 class="card-title font-reg">
                                    Rule that creates new information from existing information
                                    based on mathematical computation.
                                </h6>
                            </div>
                        </div>
                        <div class="qrule_card col-md-4 m-4 border rounded-2 "  style={{"background-color": "#D3E8FD"}}>
                            <div class="  card-body font-reg">
                                <h5 class="card-title" style={{"color": "black"}}>
                                    Inferences
                                </h5>
                                <h6 class="card-title font-reg">
                                    Rule that creates new information from existing information.
                                    Result is a piece of knowledge used as a new fact for the rule engine to consider.
                                </h6>
                            </div>
                        </div>
                    </div>                   
                </div>
                <div className="mt-5">
            <hr />
            <h2 className='font-semi text-center mt-5 mb-3 pb-5'>Case Study</h2>

            <h5 className='font-reg'>QRulez in-memory processing feature empowered one of the lender to evaluate every loan application against 21 loan schemes and each scheme involving more than 15 business rules for eligibility check and 10 business rules for eligibility calculations and all these rules are processed in fraction of seconds.</h5>
            </div>
            
                
            </div>
        </div>
      <Footer/>
    </>
  )
}

export default Qrulz
