import React, { useEffect, useState } from 'react'
import '../Career/career.css'
import Navbar2 from '../Navbar/Navbar2'
import Footer from '../Footer/Footer'
import Breadcrupms from '../Breadcrupms/Breadcrupms'
import CareerCard from './CareerCard';
import Slider from "react-slick";
import axios from 'axios'
// import { useNavigate } from 'react-router-dom'



const Responsive = (props) => {

  // const navigate = useNavigate()  
  const [jobDetailsArray, setJobDetailsArray] = useState([])

  var settings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 2,
    slidesToScroll: 1,
    initialSlide: 0,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: true,
          dots: true
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 2
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ]
  }

  const createJobDataBundle = (apiResponse) => {
    const jobData = []
    for (let i = 0; i < 2; i++) {
      const jobDataBundle = {
        jobId: apiResponse.respBody[i]?.id,
        location: apiResponse.respBody[i]?.location ,
        position: apiResponse.respBody[i]?.jobtitle ,
        overview: apiResponse.respBody[i]?.overview,
        responsibilites: apiResponse.respBody[i]?.responsibilites,
        experience: apiResponse.respBody[i]?.eduExperience,
        technical: apiResponse.respBody[i]?.requiredSkills,
        selection: apiResponse.respBody[i]?.physicalRemote
        // location: CareerData[i]?.location ,
        // position: CareerData[i]?.jobtitle ,
        // overview: CareerData[i]?.overview,
        // responsibilites: CareerData[i]?.Responsibilites,
        // experience: CareerData[i]?.Exp,
        // technical: CareerData[i]?.Technical,
        // selection: CareerData[i]?.Selection,
      }
      jobData.push(jobDataBundle)
    }
    setJobDetailsArray(jobData)
  }

  const handlePosition = async() => {
    await axios.get("https://leaves.quantafic.in/api/qbs/positions" , {
      headers: {
        'Content-Type' : 'application/json',
        'Cache-Control': 'no-cache',
        'Access-Control-Allow-Origin': '*',
        'Access-Control-Allow-Methods': 'POST, GET',
        'Access-Control-Allow-Headers ': " Origin, Content-Type, Accept, Authorization",
        "Referrer-Policy": 'origin',
        'Access-Control-Allow-Credentials' : 'true',
        'crossdomain': 'true',
        'mode': 'no-cors'
      }
    })
      .then((response) => {
        createJobDataBundle(response.data)
        console.log(response);
      }
      )
      .catch((error) => {
        console.log(error);
      }
      )
  }

 useEffect(() => {
    handlePosition()
  } , [])


  return (
    <div>
      <Navbar2 />
      <Breadcrupms title = "Career"/>
      <div className="slickcarousel">
        <Slider {...settings}>
          {jobDetailsArray.map((jobObj, index) => {
            return (
              <CareerCard key={index} {...jobObj}/>
            )
          })}
        </Slider>
      </div>
      <Footer />
    </div>
  );
}

{/* <div>
          <CareerCard jobtitle='Software Engineer' location='Mumbai' exp='1 - 2 years' overview='Lorem ipsum dolor sit amet consectetur. Viverra duis aliquam aenean auctor lacus egestas nisi. Magna dolor et pellentesque nunc molestie. Tincidunt imperdiet viverra urna ut viverra massa eget sollicitudin laoreet .Ipsum mi ultrices donec volutpat diam diam magna. Sed pulvinar habitant et feugiat quis. Egestas senectus tempus sit integer ' />
          </div> */}

          // link={'/viewcard/' + jobObj.jobId}
          // link={'/viewcard/' + JSON.stringify(jobObj)}

export default Responsive


